import { NavigationContainer } from '@react-navigation/native';
import React from 'react';
import { AuthProvider, useAuth } from './hooks/auth';
import { ConferenceProvider } from './hooks/conference';
import RootRouter from './routers/RootRouter';
import { ActivityIndicator } from 'react-native-paper';
import { View } from 'react-native';
import {
  useFonts,
  Lato_300Light,
  Lato_300Light_Italic,
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_700Bold,
  Lato_700Bold_Italic,
  Lato_900Black,
  Lato_900Black_Italic,
} from '@expo-google-fonts/lato';
import colors from './util/colors';


const WebApp = () => {
  return (
    <NavigationContainer>
      <AuthProvider>
        <OnlyRenderWhenInitialLoaded />
      </AuthProvider>
    </NavigationContainer>
    );
}

const OnlyRenderWhenInitialLoaded = () => {
  const { initialLoading } = useAuth();
  let [fontLoaded] = useFonts({
    Lato_300Light,
    Lato_300Light_Italic,
    Lato_400Regular,
    Lato_400Regular_Italic,
    Lato_700Bold,
    Lato_700Bold_Italic,
    Lato_900Black,
    Lato_900Black_Italic,

  });
  if(initialLoading || !fontLoaded) {
    return (<View
            resizeMode="stretch"
            style={{
              flex: 1,
              width: null,
              height: null,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:"white"
            }}>
              <ActivityIndicator color={colors.blue} size={"large"}/>
            </View>);
  }
  return (
    <ConferenceProvider>
        <RootRouter/> 
    </ConferenceProvider>
  )
}

export default WebApp;