import React from 'react';
import { Button, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { Modal } from 'react-native-paper';
import { useAuth } from '../hooks/auth';
import LatoText from './LatoText';

const LogoutModal= ({visible, setVisible}) => {
  const hideModal = () => setVisible(false);
  const auth = useAuth();  
  return (
    <View>
      <Modal
            animationType="slide"
            visible={visible}
            transparent={true}
            onRequestClose={hideModal}
          > <TouchableWithoutFeedback>
          <View style={styles.modalView}>
            <TouchableOpacity
                style={styles.centeredView}
                onPress={hideModal}
            >
              <LatoText style={styles.buttonTextStyle}>Você deseja realmente sair?</LatoText>
              <View style={{flexDirection:"row", justifyContent:"space-between", marginTop: 20}}>
                <Button onPress={hideModal} color="#184274">Cancelar</Button>
                <Button onPress={() => auth.signOut()} color="#184274">Adicionar</Button>
              </View>
            </TouchableOpacity>
                </View>
            </TouchableWithoutFeedback>
          </Modal>
        </View>);
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "white",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  buttonStyle: {
    backgroundColor: '#307ecc',
    color: '#FFFFFF',
    alignItems: 'center',
    borderRadius: 15,
    paddingVertical: 2,
    paddingHorizontal: 20,
    marginTop: 10,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 18,
  },
  textStyle: {
    backgroundColor: '#fff',
    fontSize: 20,
    marginBottom: 10,
    marginHorizontal: 40,
    textAlign: 'center',
  },
});

export default LogoutModal;