import * as React from 'react';
import { Modal, StyleSheet, View, TouchableOpacity, TouchableWithoutFeedback } from "react-native";
import LatoText from '../../../components/LatoText';
import { Ionicons } from '@expo/vector-icons';

export default function SubscriptionModal({ visible, setVisible, subscriptionMessage, modalColor }) {
  const hideModal = () => setVisible(false);

  React.useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        hideModal();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={hideModal}
      >
        <TouchableOpacity
          style={styles.centeredView}
          onPress={hideModal}
        >
          <TouchableWithoutFeedback>
            <View style={[
              styles.modalView, { 
                backgroundColor: modalColor,
                shadowColor: modalColor,
                shadowOffset: {
                  width: 0,
                  height: 2
                },
                shadowOpacity: 0.5,
                shadowRadius: 6,
                elevation: 6 
            }]}>
              <LatoText style={styles.message} >
                {subscriptionMessage}
              </LatoText>

                <TouchableOpacity onPress={hideModal} >
                  <Ionicons name="close" size={50} style={styles.message} />
                </TouchableOpacity>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end", 
    paddingBottom: "30%"
  },
  modalView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
    borderRadius: 5,
    width: 285,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
  },
  message: {
    fontSize: 12,
    fontWeight: 400,
    color: '#ffffff',
  }
});


