import * as React from 'react';
import { Portal, Text, Button, Provider } from 'react-native-paper';
import { Modal, StyleSheet, View, TouchableOpacity, TouchableWithoutFeedback} from "react-native";
import ProfileEditInfo from './ProfileEditInfo';
import * as DocumentPicker from 'expo-document-picker';
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

import api from '../util/axios';

export default function CVModal({visible, setVisible, form, setForm}){

  const [singleFile, setSingleFile] = React.useState(null);
 
  const selectFile = React.useCallback(async () => {
    // Opening Document Picker to select one file
    try {
      DocumentPicker.getDocumentAsync({
        type: 'application/pdf',
      }).then((res) => {
      // Setting the state to show single file attributes
      setSingleFile(res.output[0]);
      });
    } catch (err) {
      setSingleFile(null);
      console.log(err);
    }
  }, []);

  const hideModal = () => setVisible(false);

  // Handles the submit of the file
  const handleSubmit = () => {
    if (singleFile != null) {
    hideModal();
    // Prepares to send whole file to backend
    const file = new FormData();
    file.append('file', singleFile);
    api.post('/webapp/user/uploadCV', file, {
      headers: {
      'Content-Type': 'multipart/form-data;',
    }}).then(res => {
      setForm(prev => {
        return {
          ...prev,
          resumeUrl: res.data.url
        }
      });
      // Update user profile so that the resumeUrl is updated
      api.put(`/webapp/user`, {...form, resumeUrl: res.data.url})
    }).catch(err => {
      console.log(err);
    });
  }
  else {
    alert('Please Select File first');
  }
}

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={hideModal}
      >
        <TouchableOpacity
            style={styles.centeredView}
            onPress={hideModal}
        >
            <TouchableWithoutFeedback>
                <View style={styles.modalView}>
                    <FontAwesome5
                      name={'file-pdf'}
                      color={'#333'}
                      size={60}
                      style={{marginBottom: 10}}
                    />
                    {/*Showing the data of selected Single file*/}
                    {singleFile != null ? (
                      <Text style={styles.textStyle}>
                        Arquivo selecionado: {singleFile.name ? singleFile.name : ''}
                      </Text>
                    ) : null}
                    <TouchableOpacity
                      style={styles.buttonStyle}
                      activeOpacity={0.5}
                      onPress={selectFile}>
                      <Text style={styles.buttonTextStyle}>Selecione Um Arquivo</Text>
                    </TouchableOpacity>
                    <Text style={{fontSize: 18, color:"gray", marginTop: 6}}>(Somente PDF)</Text>
                  <View style={{flexDirection:"row", justifyContent:"space-between", marginTop: 20}}>
                    <Button onPress={hideModal} color="#184274">Cancelar</Button>
                    <Button onPress={handleSubmit} color="#184274">Adicionar</Button>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    },
    buttonStyle: {
      backgroundColor: '#307ecc',
      color: '#FFFFFF',
      alignItems: 'center',
      borderRadius: 15,
      paddingVertical: 2,
      paddingHorizontal: 20,
      marginTop: 10,
    },
    buttonTextStyle: {
      color: '#FFFFFF',
      paddingVertical: 10,
      fontSize: 18,
    },
    textStyle: {
      backgroundColor: '#fff',
      fontSize: 20,
      marginBottom: 10,
      marginHorizontal: 40,
      textAlign: 'center',
    },
  });

 
const App = () => {
  
  return (
    <View style={styles.mainBody}>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 30, textAlign: 'center' }}>
          React Native File Upload Example
        </Text>
        <Text
          style={{
            fontSize: 25,
            marginTop: 20,
            marginBottom: 30,
            textAlign: 'center',
          }}>
          www.aboutreact.com
        </Text>
      </View>

    </View>
  );
};
 

