import api from "../util/axios";

export const getAllInitiatives = async (confSlug: string) => {
  return api.get(`/webapp/${confSlug}/initiative`);
}

export const getParticipantInitiatives = async (confSlug: string) => {
  return api.get(`/webapp/${confSlug}/initiative/user`);
}

export const connectToInitiative = async (confSlug: string, initiativeId: string) => {
  return api.post(`/webapp/${confSlug}/initiative/${initiativeId}`);
}

export const sendQuestion = async (confSlug: string, initiativeName: string, question: string) => {
  return api.post(`/webapp/${confSlug}/initiative/question`, {initiative: initiativeName, question});
}

export const submitRating = async (confSlug: string, initiativeId: string, rating: number) => {
  return api.post(`/webapp/${confSlug}/initiative/${initiativeId}/rating`, {rating});
}

export const getInitiativeById = async (initiativeId: string) => {
  return api.get(`webapp/initiative/${initiativeId}`);
}

export const getParticipantsRegisteredToInitiative = async (initiativeId: string) => {
  return api.get(`webapp/registered/${initiativeId}`);
}

export const getRegisteredInitiatives = async (participantId: string) => {
  return api.get(`webapp/registration/participant/${participantId}`);
}

export const registerToInitiative = async (initiativeId: string, participantId: string) => {
  return api.post(`webapp/initiative/${initiativeId}/participant/${participantId}`);
}

export const unregisterFromInitiative = async (initiativeId: string, participantId: string) => {
  return api.delete(`webapp/initiative/${initiativeId}/participant/${participantId}`);
}
