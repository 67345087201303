import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ImageBackground,
  Image,
} from "react-native";
import { useAuth } from "../../../hooks/auth";
import * as Animatable from "react-native-animatable";
import LatoText from "../../../components/LatoText";
import Icon from "react-native-vector-icons/MaterialIcons"; // Certifique-se de ter essa biblioteca instalada
// import { flexbox } from "native-base/lib/typescript/theme/styled-system";

const Login = ({ navigation }) => {
  const { signIn } = useAuth();
  const [errorMsg, setErrorMsg] = React.useState("");

  const [data, setData] = React.useState({
    email: "",
    password: "",
    check_textInputChange: false,
    secureTextEntry: true,
    isValidUser: true,
    isValidPassword: true,
  });

  const textInputChange = (val) => {
    if (validateEmail(val)) {
      setData({
        ...data,
        email: val,
        check_textInputChange: true,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        email: val,
        check_textInputChange: false,
        isValidUser: false,
      });
    }
  };

  const validateEmail = (str) => {
    const pattern = new RegExp(
      "^[a-zA-Z0-9_+&*-]+(?:\\." +
        "[a-zA-Z0-9_+&*-]+)*@" +
        "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
        "A-Z]{2,7}$"
    );

    return str && pattern.test(str);
  };

  const handlePasswordChange = (val) => {
    setData({
      ...data,
      password: val,
      isValidPassword: val.trim().length >= 8,
    });
  };

  const updateSecureTextEntry = () => {
    setData({
      ...data,
      secureTextEntry: !data.secureTextEntry,
    });
  };

  const handleValidUser = (val) => {
    setData({
      ...data,
      isValidUser: validateEmail(val),
    });
  };

  const loginHandle = async (email, password) => {
    if (email.length === 0 || password.length === 0) {
      setErrorMsg("Erro: Campo(s) de email e/ou senha não podem estar vazios.");
      return;
    }

    const response = await signIn(data.email, data.password);
    console.log(response);

    if (response) {
      setErrorMsg("Email ou senha incorretos.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginHandle(data.email, data.password);
    }
  };

  return (
    <ImageBackground
      source={require("../../../assets/bg.png")}
      style={{ flex: 1, padding: 0 }}
    >
      <View style={styles.container}>
        <Image
          source={require("../../../assets/Vector.png")}
          style={styles.logo}
        />
        <LatoText style={[styles.title, { color: "white" }]}>
          Entre na sua Conta
        </LatoText>
        <View style={styles.contentContainer}>
          <View style={styles.inputContainer}>
            <TextInput
              placeholder="Digite seu email"
              placeholderTextColor="#666666"
              style={[styles.textInput, { color: "black" }]}
              autoCapitalize="none"
              onChangeText={(val) => textInputChange(val)}
              onEndEditing={(e) => handleValidUser(e.nativeEvent.text)}
              onKeyPress={(e) => handleKeyDown(e)}
              underlineColorAndroid="transparent" // Remove o fundo azul
            />
            {data.isValidUser ? null : (
              <Animatable.View animation="fadeInLeft" duration={500}>
                <LatoText thin style={styles.errorMsg1}>
                  Formato de email invalido.
                </LatoText>
              </Animatable.View>
            )}
            <View style={styles.separatorLine} />
            <View style={styles.passwordContainer}>
              <TextInput
                placeholder="Digite sua senha"
                placeholderTextColor="#666666"
                secureTextEntry={data.secureTextEntry}
                style={[styles.textInput, { color: "black" }]}
                autoCapitalize="none"
                onChangeText={(val) => handlePasswordChange(val)}
                onKeyPress={(e) => handleKeyDown(e)}
                underlineColorAndroid="transparent" // Remove o fundo azul
              />
              <TouchableOpacity
                onPress={updateSecureTextEntry}
                style={styles.eyeIcon}
              >
                <Icon
                  name={data.secureTextEntry ? "visibility-off" : "visibility"}
                  size={25}
                  color="#666"
                />
              </TouchableOpacity>
            </View>
            {data.isValidPassword ? null : (
              <Animatable.View animation="fadeInLeft" duration={500}>
                <LatoText thin style={styles.errorMsg2}>
                  Senha precisa ter pelo menos 8 caracteres.
                </LatoText>
              </Animatable.View>
            )}
            {errorMsg ? (
              <Animatable.View animation="fadeInLeft" duration={500}>
                <LatoText thin style={styles.errorMsg2}>
                  {errorMsg}
                </LatoText>
              </Animatable.View>
            ) : null}
          </View>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("NewUserPage", { type: "esqueceu" })
            }
          >
            <LatoText style={styles.forgotPasswordText}>
              Esqueceu sua Senha?
            </LatoText>
          </TouchableOpacity>

          <View style={styles.button}>
            <TouchableOpacity
              style={styles.signIn}
              onPress={async () => loginHandle(data.email, data.password)}
            >
              <LatoText bold style={[styles.textSign, { color: "#fff" }]}>
                Entrar
              </LatoText>
            </TouchableOpacity>
          </View>

          <View style={styles.lineLogoComp}>
            <View style={styles.line} />
            <Image
              source={require("../../../assets/adaptive-icon.png")}
              style={styles.logoColor}
            />{" "}
            <View style={styles.line} />
          </View>

          <View style={styles.footer}>
            <LatoText style={styles.footerText}>
              Não tem uma conta?
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("NewUserPage", { type: "Cadastre-se" })
                }
              >
                <LatoText style={styles.signUpText}> Cadastre-se</LatoText>
              </TouchableOpacity>
            </LatoText>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default Login;

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 100,
    marginBottom: 45,
    marginTop: 60,
    alignSelf: "center",
    resizeMode: "contain",
  },
  title: {
    fontSize: 23,
    fontWeight: "bold",
    color: "#fff",
    padding: 20,
    alignSelf: "flex-start", // Align title to the left
  },
  contentContainer: {
    backgroundColor: "#fff", // Background color for visibility
    width: "100%", // Width of the container
    flex: 1, // Take remaining space
    elevation: 2, // For shadow on Android
    shadowColor: "#000", // Shadow for iOS
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    justifyContent: "flex-start", // Align items at the top
    alignItems: "center", // Center content horizontally
    paddingTop: 25, // Padding inside the container
    paddingBottom: 25, // Padding inside the container
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    borderColor: "lightgray",
    borderWidth: 1,
    borderRadius: 10,
    paddingTop: 15,
    width: 250,
    marginBottom: 10,
  },
  passwordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    marginBottom: 10,
  },
  eyeIcon: {
    // position: "absolute",
    // right: 10,
    marginRight: 20,
  },
  separatorLine: {
    height: 1,
    backgroundColor: "#D3D3D3",
    marginTop: 13,
    marginBottom: 10,
  },
  container: {
    flex: 1,
    paddingHorizontal: 0,
    justifyContent: "center",
  },
  textInput: {
    flex: 1,
    marginLeft: 10,
    backgroundColor: "transparent",
  },
  errorMsg1: {
    color: "#FF0000",
    fontSize: 14,
    paddingLeft: 10,
    paddingTop: 10,
  },
  errorMsg2: {
    color: "#FF0000",
    fontSize: 14,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  button: {
    alignItems: "center",
    marginTop: 10,
    width: 250,
  },
  signIn: {
    height: 40,
    width: "100%",
    backgroundColor: "#033A0C",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  lineLogoComp: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    marginBottom: 5,
    marginTop: 25,
  },
  line: {
    flexGrow: 1,
    height: 2,
    backgroundColor: "rgba(3, 58, 12, 0.5)",
    minWidth: 90, // Ensure the line has at least some visible width
  },
  logoColor: {
    width: 50,
    height: 50,
    resizeMode: "contain",
    marginHorizontal: 5, // Add some space around the logo
  },
  textSign: {
    fontSize: 18,
    fontWeight: "bold",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  footerText: {
    fontSize: 16,
    color: "#666", // Cinza
  },
  signUpText: {
    fontSize: 16,
    color: "#033A0C",
    fontWeight: "bold",
  },
  forgotPasswordText: {
    fontSize: 14,
    color: "#5D9F78",
    fontWeight: "bold",
    marginLeft: 110,
    marginBottom: 10,
  },
});
