import React, { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { useConference } from "../../../hooks/conference";
import { getSchedule } from "../../../services/scheduleServices";
import { getAllInitiatives } from "../../../services/initiativesService";
import {
  registerToInitiative,
  getParticipantsRegisteredToInitiative,
  getRegisteredInitiatives,
  unregisterFromInitiative,
} from "../../../services/initiativesService";
import LatoText from "../../../components/LatoText";
import Loading from "../../../hooks/loading";
import SubscriptionModal from "./SubscriptionModal";

const AllInitiatives = () => {
  const {
    currentSelectedConference,
    currentSelectedConferenceId,
    participantId,
  } = useConference();
  const [allInitiatives, setAllInitiatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userInitiatives, setUserInitiatives] = useState([]);
  const [subscriptionUpdate, setSubscriptionUpdate] = useState(false);
  const [subscribingId, setSubscribingId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalColor, setModalColor] = useState("");
  const [buttonVisibility, setButtonVisibility] = useState({});

  useFocusEffect(
    useCallback(() => {
      async function fetch() {
        if (currentSelectedConference && currentSelectedConferenceId) {
          try {
            const resParticipantInitiatives = await getRegisteredInitiatives(
              participantId
            );
            const resAllInitiatives = await getAllInitiatives(
              currentSelectedConference
            );
            const resSchedule = await getSchedule(currentSelectedConferenceId);

            const initiativesMap = new Map(
              resAllInitiatives.data.initiatives.map((i) => [
                i.id,
                { type: i.type, spotsAvailable: i.spotsAvailable },
              ])
            );

            const userInitiativesIds =
              resParticipantInitiatives.data.initiatives[0].participantInitiatives.map(
                (init) => init.initiativeId
              );

            const allInitiativesWithTime = [];

            resSchedule.data.slice(0, 2).forEach((day) => {
              day.times.forEach((time) => {
                time.events.forEach((event) => {
                  if (
                    event.initiative &&
                    userInitiativesIds.includes(event.initiative.id)
                  ) {
                    allInitiativesWithTime.push({
                      name: event.initiative.name,
                      time: time.time,
                      day: day.day,
                    });
                  }
                });
              });
            });

            setUserInitiatives(allInitiativesWithTime);

            const days = await Promise.all(
              resSchedule.data.map(async (day, index) => {
                const initiatives = await Promise.all(
                  day.times.map(async (time) => {
                    const events = await Promise.all(
                      time.events.map(async (event) => {
                        const initiativeData = initiativesMap.get(
                          event.initiative?.id
                        );
                        if (
                          initiativeData &&
                          initiativeData.type === "initiative"
                        ) {
                          const participantsPerInitiatives =
                            await getParticipantsRegisteredToInitiative(
                              event.initiative?.id
                            );
                          const filledSpots =
                            participantsPerInitiatives.data.participants.length;
                          let remainingSpots;
                          if (initiativeData.spotsAvailable > 0) {
                            remainingSpots =
                              initiativeData.spotsAvailable - filledSpots;
                          } else if (initiativeData.spotsAvailable == 0) {
                            remainingSpots = 0;
                          }
                          return {
                            id: event.initiative.id,
                            name: event.initiative.name,
                            time: time.time,
                            spotsAvailable: remainingSpots,
                          };
                        }
                      })
                    );
                    return events.filter((event) => event !== undefined);
                  })
                );

                return {
                  day: day.day,
                  initiatives: initiatives.flat(),
                };
              })
            );
            // estado de visibilidade do botão
            const initialButtonVisibility = {};
            allInitiativesWithTime.forEach((initiative) => {
              initialButtonVisibility[initiative.id] = true;
            });

            setButtonVisibility(initialButtonVisibility);
            setAllInitiatives(days);
          } catch (error) {
            console.log("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        } else {
          console.log("Conference or Conference ID not set.");
          setLoading(false);
        }
      }
      fetch();
    }, [
      subscriptionUpdate,
      currentSelectedConference,
      currentSelectedConferenceId,
    ])
  );

  const individualInitiativesSet = new Set(userInitiatives.map((i) => i.name));

  const timesSet = new Set(userInitiatives.map((i) => `${i.time}-${i.day}`));

  const handleSubscribe = async (initiativeId, time, day, remainingSpots) => {
    try {
      const timeDayKey = `${time}-${day}`;
      if (timesSet.has(timeDayKey)) {
        setModalMessage(
          "Não foi possível inscrever-se nessa iniciativa. Você já está inscrito em outra iniciativa neste mesmo horário e dia."
        );
        setModalColor("#CF364C");
        setIsOpen(true);
      } else if (remainingSpots > 0) {
        const result = await registerToInitiative(initiativeId, participantId);
        setSubscribingId(initiativeId);
        setSubscriptionUpdate((prev) => !prev);
        setModalMessage("Inscrição concluída com sucesso.");
        setModalColor("#049C5C");
        setIsOpen(true);
        if (result.success) {
          const newInitiative = allInitiatives
            .flatMap((day) => day.initiatives)
            .find((initiative) => initiative.id === initiativeId);
          if (newInitiative) {
            onSubscribeSuccess(newInitiative);
          }
        }
      } else {
        setModalMessage("Não é possível se inscrever nessa iniciativa.");
        setModalColor("#CF364C");
        setIsOpen(true);
      }
    } catch (error) {
      setModalMessage("Erro ao tentar se inscrever.");
      setModalColor("#CF364C");
      setIsOpen(true);
      console.log("Erro ao inscrever:", error);
    }
  };

  const handleUnsubscribe = async (initiativeId) => {
    try {
      await unregisterFromInitiative(initiativeId, participantId);

      // Update userInitiatives state by removing the unsubscribed initiative
      setUserInitiatives((prevInitiatives) =>
        prevInitiatives.filter((initiative) => initiative.id !== initiativeId)
      );

      // Update the button visibility state to show "Inscreva-se" after unsubscribing
      setButtonVisibility((prevState) => ({
        ...prevState,
        [initiativeId]: true, // Make the subscribe button visible
      }));

      // Optionally update the subscription state to refetch data
      setSubscriptionUpdate((prev) => !prev);

      // Show modal with a success message
      setModalMessage("Cancelamento concluído.");
      setModalColor("#CF364C");
      setIsOpen(true);
    } catch (error) {
      console.log("Error unsubscribing from initiative:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  const colors = ["#5D9F78", "#7AB241"];

  return (
    <View>
      {allInitiatives.map((day, dayIndex) => (
        <View key={day.day}>
          <LatoText style={styles.dayHeader}>Dia {day.day}</LatoText>
          {day.initiatives.map((item, index) => (
            <View key={index}>
              <View
                style={[
                  styles.initiativeContainer,
                  {
                    backgroundColor: colors[dayIndex % colors.length], // Cycle through colors
                  },
                ]}
              >
                {" "}
                <View style={styles.initiativeTitle}>
                  <LatoText style={styles.initiativeTime}>{item.time}</LatoText>
                  <LatoText
                    style={styles.initiativeName}
                    bold
                    numberOfLines={1}
                  >
                    {item.name}
                  </LatoText>
                </View>
                {individualInitiativesSet.has(item.name) ? (
                  // User is subscribed to the initiative
                  <TouchableOpacity
                    style={styles.unsubscribeButton}
                    onPress={() => handleUnsubscribe(item.id)}
                  >
                    <LatoText style={styles.unsubscribeButtonText}>
                      Cancelar Inscrição
                    </LatoText>
                  </TouchableOpacity>
                ) : (
                  // User is not subscribed to the initiative
                  <TouchableOpacity
                    style={styles.botaoInscrever}
                    onPress={() =>
                      handleSubscribe(
                        item.id,
                        item.time,
                        day.day,
                        item.spotsAvailable
                      )
                    }
                  >
                    <LatoText style={styles.inscrevaseText}>
                      Inscreva-se
                    </LatoText>
                  </TouchableOpacity>
                )}
              </View>
              <View style={styles.secondInitiativeContainer}>
                <LatoText style={styles.initiativeSpots}>
                  {item.spotsAvailable} vagas disponíveis
                </LatoText>
              </View>
            </View>
          ))}
        </View>
      ))}
      <SubscriptionModal
        visible={isOpen}
        setVisible={setIsOpen}
        subscriptionMessage={modalMessage}
        modalColor={modalColor}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  dayHeader: {
    fontSize: 14,
    color: "#005248",
    fontWeight: 900,
    paddingVertical: 10,
    paddingHorizontal: 5,
    textAlign: "center",
  },
  initiativeContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#5D9F78",
    padding: 10,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    // height: 53,
  },
  secondInitiativeContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    backgroundColor: "#DCE5DE",
    padding: 10,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    height: 40,
  },
  initiativeTitle: {
    flexDirection: "column",
    flex: 1,
    flexWrap: "wrap",
  },
  initiativeName: {
    color: "#fff",
    fontSize: 14,
    flex: 1,
    flexShrink: 1,
  },
  initiativeTime: {
    color: "#fff",
    marginRight: 10,
  },
  initiativeSpots: {
    color: "#033A0C",
  },
  botaoInscrever: {
    backgroundColor: "#033A0C",
    padding: 10,
    borderRadius: 30,
    width: 120, // Use consistent width
    height: 27,
    justifyContent: "center", // Centering vertically
    alignItems: "center", // Centering horizontally
    color: "#fff",
    marginLeft: 18,
    textAlign: "center", // Ensure text is centered within the button
    textDecorationStyle: "bold",
  },
  inscrevaseText: {
    color: "#fff",
    fontSize: 12,
    flexShrink: 0,
  },
  unsubscribeButton: {
    backgroundColor: "#033A0C",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 30,
    marginLeft: 18,
    height: 27,
    width: 120,
    textAlign: "center",
    justifyContent: "center",
  },
  unsubscribeButtonText: {
    color: "#fff",
    fontSize: 12,
    flexShrink: 0,
  },
});

export default AllInitiatives;
