import { useState, useEffect } from "react";
import useImage from "./image";

const useParceiros = () => {
  const [parceirosInfos, setparceirosInfos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const {
    image: btgParceiros,
    loading: btgParceirosLoading,
    error: btgParceirosError,
  } = useImage("btgParceiros.png");
  const {
    image: bainParceiros,
    loading: bainParceirosLoading,
    error: bainParceirosError,
  } = useImage("bainParceiros.png");
  // const {
  //   image: ambulisParceiros,
  //   loading: ambulisParceirosLoading,
  //   error: ambulisParceirosError,
  // } = useImage("ambulisParceiros.png");
  // const {
  //   image: stoneParceiros,
  //   loading: stoneParceirosLoading,
  //   error: stoneParceirosError,
  // } = useImage("stoneParceiros.png");
  // const {
  //   image: alphaParceiros,
  //   loading: alphaParceirosLoading,
  //   error: alphaParceirosError,
  // } = useImage("alphaParceiros.png");
  // const {
  //   image: ambevParceiros,
  //   loading: ambevParceirosLoading,
  //   error: ambevParceirosError,
  // } = useImage("ambevParceiros.png");
  // const {
  //   image: atgParceiros,
  //   loading: atgParceirosLoading,
  //   error: atgParceirosError,
  // } = useImage("atgParceiros.png");
  // const {
  //   image: brParceiros,
  //   loading: brParceirosLoading,
  //   error: brParceirosError,
  // } = useImage("brParceiros.png");
  // const {
  //   image: canamParceiros,
  //   loading: canamParceirosLoading,
  //   error: canamParceirosError,
  // } = useImage("canamParceiros.png");
  // const {
  //   image: driftwoodParceiros,
  //   loading: driftwoodParceirosLoading,
  //   error: driftwoodParceirosError,
  // } = useImage("driftwoodParceiros.png");
  // const {
  //   image: galapagosParceiros,
  //   loading: galapagosParceirosLoading,
  //   error: galapagosParceirosError,
  // } = useImage("galapagosParceiros.png");
  const {
    image: movtechParceiros,
    loading: movtechParceirosLoading,
    error: movtechParceirosError,
  } = useImage("movtechParceiros.png");
  const {
    image: newhackParceiros,
    loading: newhackParceirosLoading,
    error: newhackParceirosError,
  } = useImage("newhackParceiros.png");
  // const {
  //   image: poatekParceiros,
  //   loading: poatekParceirosLoading,
  //   error: poatekParceirosError,
  // } = useImage("poatekParceiros.png");
  // const {
  //   image: purdueParceiros,
  //   loading: purdueParceirosLoading,
  //   error: purdueParceirosError,
  // } = useImage("purdueParceiros.png");
  // LOGO
  const {
    image: btgLogo,
    loading: btgLogoLoading,
    error: btgLogoError,
  } = useImage("btgLogo.png");
  const {
    image: bainLogo,
    loading: bainLogoLoading,
    error: bainLogoError,
  } = useImage("bainLogo.png");
  // const {
  //   image: ambulisLogo,
  //   loading: ambulisLogoLoading,
  //   error: ambulisLogoError,
  // } = useImage("ambulisLogo.png");
  // const {
  //   image: stoneLogo,
  //   loading: stoneLogoLoading,
  //   error: stoneLogoError,
  // } = useImage("stoneLogo.png");
  // const {
  //   image: alphaLogo,
  //   loading: alphaLogoLoading,
  //   error: alphaLogoError,
  // } = useImage("alphaLogo.png");
  // const {
  //   image: ambevLogo,
  //   loading: ambevLogoLoading,
  //   error: ambevLogoError,
  // } = useImage("ambevLogo.png");
  // const {
  //   image: atgLogo,
  //   loading: atgLogoLoading,
  //   error: atgLogoError,
  // } = useImage("atgLogo.png");
  // const {
  //   image: brLogo,
  //   loading: brLogoLoading,
  //   error: brLogoError,
  // } = useImage("brLogo.png");
  // const {
  //   image: canamLogo,
  //   loading: canamLogoLoading,
  //   error: canamLogoError,
  // } = useImage("canamLogo.png");
  // const {
  //   image: driftwoodLogo,
  //   loading: driftwoodLogoLoading,
  //   error: driftwoodLogoError,
  // } = useImage("driftwoodLogo.png");
  // const {
  //   image: galapagosLogo,
  //   loading: galapagosLogoLoading,
  //   error: galapagosLogoError,
  // } = useImage("galapagosLogo.png");
  const {
    image: movtechLogo,
    loading: movtechLogoLoading,
    error: movtechLogoError,
  } = useImage("movtechParceiros.png");
  const {
    image: newhackLogo,
    loading: newhackLogoLoading,
    error: newhackLogoError,
  } = useImage("newhackParceiros.png");
  // const {
  //   image: poatekLogo,
  //   loading: poatekLogoLoading,
  //   error: poatekLogoError,
  // } = useImage("poatekLogo.png");
  // const {
  //   image: purdueLogo,
  //   loading: purdueLogoLoading,
  //   error: purdueLogoError,
  // } = useImage("purdueLogo.png");
  // const {
  //   image: fundacaoParceiros,
  //   loading: fundacaoParceirosLoading,
  //   error: fundacaoParceirosError,
  // } = useImage("fundacaoParceiros.png");
  // const {
  //   image: itauParceiros,
  //   loading: itauParceirosLoading,
  //   error: itauParceirosError,
  // } = useImage("itauParceiros.png");
  // const {
  //   image: capilanoParceiros,
  //   loading: capilanoParceirosLoading,
  //   error: capilanoParceirosError,
  // } = useImage("capilanoParceiros.png");
  // const {
  //   image: fundacaoLogo,
  //   loading: fundacaoLogoLoading,
  //   error: fundacaoLogoError,
  // } = useImage("fundacaoLogo.png");
  // const {
  //   image: itauLogo,
  //   loading: itauLogoLoading,
  //   error: itauLogoError,
  // } = useImage("itauLogo.png");
  // const {
  //   image: capilanoLogo,
  //   loading: capilanoLogoLoading,
  //   error: capilanoLogoError,
  // } = useImage("capilanoLogo.png");

  useEffect(() => {
    if (
      // !driftwoodParceirosLoading &&
      // !atgParceirosLoading &&
      // !alphaParceirosLoading &&
      // !purdueParceirosLoading &&
      // !stoneParceirosLoading &&
      !btgParceirosLoading &&
      // !ambulisParceirosLoading &&
      // !ambevParceirosLoading &&
      // !brParceirosLoading &&
      // !canamParceirosLoading &&
      // !galapagosParceirosLoading &&
      !newhackParceirosLoading &&
      // !poatekParceirosLoading &&
      !movtechParceirosLoading &&
      // LOGO
      // !driftwoodLogoLoading &&
      // !atgLogoLoading &&
      // !alphaLogoLoading &&
      // !purdueLogoLoading &&
      // !stoneLogoLoading &&
      !btgLogoLoading &&
      // !ambulisLogoLoading &&
      // !ambevLogoLoading &&
      // !brLogoLoading &&
      // !canamLogoLoading &&
      // !galapagosLogoLoading &&
      !newhackLogoLoading &&
      // !poatekLogoLoading &&
      !movtechLogoLoading &&
      !bainParceirosLoading &&
      !bainLogoLoading
      // !fundacaoParceirosLoading &&
      // !fundacaoLogoLoading &&
      // !itauLogoLoading &&
      // !itauParceirosLoading &&
      // !capilanoLogoLoading &&
      // !capilanoParceirosLoading
    ) {
      setparceirosInfos([
        { imagem: btgParceiros, chave: "btg", logo: btgLogo },
        // { imagem: stoneParceiros, chave: "stone", logo: stoneLogo },
        // { imagem: alphaParceiros, chave: "alpha", logo: alphaLogo },
        // { imagem: ambevParceiros, chave: "ambev", logo: ambevLogo },
        // { imagem: atgParceiros, chave: "atg", logo: atgLogo },
        // { imagem: brParceiros, chave: "br", logo: brLogo },
        // { imagem: canamParceiros, chave: "canam", logo: canamLogo },
        // { imagem: driftwoodParceiros, chave: "driftwood", logo: driftwoodLogo },
        // { imagem: galapagosParceiros, chave: "galapagos", logo: galapagosLogo },
        // { imagem: ambulisParceiros, chave: "ambulis", logo: ambulisLogo },
        { imagem: bainParceiros, chave: "bain", logo: bainLogo },
        { imagem: movtechParceiros, chave: "movtech", logo: movtechLogo },
        { imagem: newhackParceiros, chave: "newhack", logo: newhackLogo },
        // { imagem: poatekParceiros, chave: "poatek", logo: poatekLogo },
        // { imagem: purdueParceiros, chave: "purdue", logo: purdueLogo },

        // { imagem: itauParceiros, chave: "itau", logo: itauLogo },
        // { imagem: fundacaoParceiros, chave: "fundacao", logo: fundacaoLogo },
        // { imagem: capilanoParceiros, chave: "capilano", logo: capilanoLogo },
      ]);
      setLoading(false);
      setErrors(
        [
          // driftwoodParceirosError,
          // atgParceirosError,
          // alphaParceirosError,
          // purdueParceirosError,
          // stoneParceirosError,
          btgParceirosError,
          // ambulisParceirosError,
          bainParceirosError,
          // ambevParceirosError,
          // brParceirosError,
          // canamParceirosError,
          // galapagosParceirosError,
          newhackParceirosError,
          // poatekParceirosError,
          movtechParceirosError,
          // LOGO
          // driftwoodLogoError,
          // atgLogoError,
          // alphaLogoError,
          // purdueLogoError,
          // stoneLogoError,
          btgLogoError,
          // ambulisLogoError,
          // ambevLogoError,
          // brLogoError,
          // canamLogoError,
          // galapagosLogoError,
          newhackLogoError,
          // poatekLogoError,
          movtechLogoError,
          bainLogoError,
          // fundacaoParceirosError,
          // fundacaoLogoError,
          // itauLogoError,
          // itauParceirosError,
          // capilanoLogoError,
          // capilanoParceirosError
        ].filter((error) => error)
      );
    }
  }, [
    // driftwoodParceirosLoading,
    // atgParceirosLoading,
    // alphaParceirosLoading,
    // purdueParceirosLoading,
    // stoneParceirosLoading,
    btgParceirosLoading,
    // ambulisParceirosLoading,
    bainParceirosLoading,
    // ambevParceirosLoading,
    // brParceirosLoading,
    // canamParceirosLoading,
    // galapagosParceirosLoading,
    newhackParceirosLoading,
    // poatekParceirosLoading,
    movtechParceirosLoading,

    // LOGO LOADING
    // driftwoodLogoLoading,
    // atgLogoLoading,
    // alphaLogoLoading,
    // purdueLogoLoading,
    // stoneLogoLoading,
    btgLogoLoading,
    bainLogoLoading,
    // ambulisLogoLoading,
    // ambevLogoLoading,
    // brLogoLoading,
    // canamLogoLoading,
    // galapagosLogoLoading,
    newhackLogoLoading,
    // poatekLogoLoading,
    movtechLogoLoading,

    // PARCEIROS
    // driftwoodParceiros,
    // atgParceiros,
    // alphaParceiros,
    // purdueParceiros,
    // stoneParceiros,
    btgParceiros,
    // ambulisParceiros,
    bainParceiros,
    // ambevParceiros,
    // brParceiros,
    // canamParceiros,
    // galapagosParceiros,
    newhackParceiros,
    // poatekParceiros,
    movtechParceiros,

    // LOGO
    // driftwoodLogo,
    // atgLogo,
    // alphaLogo,
    // purdueLogo,
    // stoneLogo,
    btgLogo,
    bainLogo,
    // ambulisLogo,
    // ambevLogo,
    // brLogo,
    // canamLogo,
    // galapagosLogo,
    newhackLogo,
    // poatekLogo,
    movtechLogo,

    // fundacaoLogo,
    // fundacaoLogoLoading,
    // fundacaoParceiros,
    // fundacaoParceirosLoading,
    // itauLogoLoading,
    // itauParceirosLoading,
    // capilanoLogoLoading,
    // capilanoParceirosLoading,
  ]);

  return { parceirosInfos, loading, errors };
};

export default useParceiros;
