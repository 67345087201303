import * as React from 'react';
import { Portal, Text, Button, Provider, TextInput } from 'react-native-paper';
import { Modal, StyleSheet, View, TouchableOpacity, TouchableWithoutFeedback} from "react-native";
import * as DocumentPicker from 'expo-document-picker';
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import api from '../util/axios';
import LatoText from './LatoText';
import chatid from '../assets/telegram.json'
import { useConference } from '../hooks/conference';
export default function PBModal({visible, setVisible}){
  const [message, setMessage] = React.useState('');
  const hideModal = () => setVisible(false);
  const { currentSelectedConference} = useConference();
  const handleSendMessage = () => {
    api.post('/webapp/recruiter/send-message', { message: message, chatId: chatid[currentSelectedConference] })
      .then(response => {
        setVisible(false)
        console.log('Message sent!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={hideModal}
      >
        <TouchableOpacity
            style={styles.centeredView}
            onPress={hideModal}
        >
            <TouchableWithoutFeedback>
                <View style={styles.modalView}>
                  <LatoText style={{
                    fontSize: 20
                  }} >Alerta BRASA 
                    
                  </LatoText>
                  <TextInput
                    onChangeText={txt => setMessage(txt)}
                    style={styles.TextInput}
                    underlineColor='transparent'
                    placeholder="Digite sua duvida aqui"
                    mode='outlined'
                    activeOutlineColor='grey'
                    outlineColor='transparent'
                    activeUnderlineColor='transparent'

                  
                  />
                    
                  <View style={{flexDirection:"row", justifyContent:"space-between", marginTop: 20}}>
                    <Button onPress={hideModal} color="#184274">Voltar</Button>
                    <Button onPress={handleSendMessage} color="#184274">Enviar</Button>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
    textInput: {
      fontSize: '14px',
      marginTop: '10px',
      padding: '12px',
      width: '100%',
      border: 'solid',
      borderColor: 'grey',
      outlineStyle: 'none',
      borderRadius: '8px',
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    },
    buttonStyle: {
      backgroundColor: '#307ecc',
      color: '#FFFFFF',
      alignItems: 'center',
      borderRadius: 15,
      paddingVertical: 2,
      paddingHorizontal: 20,
      marginTop: 10,
    },
    buttonTextStyle: {
      color: '#FFFFFF',
      paddingVertical: 10,
      fontSize: 18,
    },
    textStyle: {
      backgroundColor: '#fff',
      fontSize: 20,
      marginBottom: 10,
      marginHorizontal: 40,
      textAlign: 'center',
    },
  });

 
const App = () => {
  
  return (
    <View style={styles.mainBody}>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 30, textAlign: 'center' }}>
          React Native File Upload Example
        </Text>
        <Text
          style={{
            fontSize: 25,
            marginTop: 20,
            marginBottom: 30,
            textAlign: 'center',
          }}>
          www.aboutreact.com
        </Text>
      </View>

    </View>
  );
};
 

