import axios from "axios";
import Constants from 'expo-constants';
const api = axios.create({
  baseURL: "https://gobrasa.io/api",
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
});

export default api
