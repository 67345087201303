import { useFocusEffect, useRoute } from "@react-navigation/native";
import React, { useCallback, useMemo, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useConference } from "../../hooks/conference";
import IInitiative from "../../interfaces/IInitiative";
import {
  getParticipantInitiatives,
  getAllInitiatives,
} from "../../services/initiativesService";
import AllInitiatives from "./personal/AllInitiatives";

type InitiativeWithRating = {
  rating?: number;
  initiative: IInitiative;
};

const InitiativesPage = () => {
  const { currentSelectedConference } = useConference();
  const [initiatives, setInitiatives] = useState<InitiativeWithRating[]>([]);

  // Fetch iniciativas quando conferência é selecionada ou quando a página é carregada
  useFocusEffect(
    useCallback(() => {
      const fetchInitiatives = async () => {
        try {
          // Verificar se uma conferência está selecionada e buscar iniciativas de participantes, caso contrário buscar todas as iniciativas
          const response = currentSelectedConference
            ? await getParticipantInitiatives(currentSelectedConference)
            : await getAllInitiatives(currentSelectedConference); // Função para buscar todas as iniciativas

          setInitiatives(response.data.initiatives);
        } catch (error) {
          console.error(error);
        }
      };

      fetchInitiatives(); // Chama a função para buscar iniciativas
    }, [currentSelectedConference])
  );

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <AllInitiatives />
        </ScrollView>{" "}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#FFFFFF",
    width: 339,
  },
  contentContainer: {
    flex: 1,
    minHeight: 200,
    maxHeight: 350,
    overflow: "hidden",
  },
  scrollContainer: {
    flexGrow: 1,
  },
  title: {
    color: "#005248",
    fontSize: 20,
    fontWeight: "900",
    marginBottom: 16,
    textAlign: "center",
  },
});

export default InitiativesPage;
