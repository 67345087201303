import React, { useState, useEffect } from "react";
import { useRoute } from "@react-navigation/native";
import {
  View,
  Platform,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ImageBackground,
  Image,
} from "react-native";
import * as Animatable from "react-native-animatable";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Feather from "react-native-vector-icons/Feather";
import {
  generateOTP,
  updateUserPassword,
} from "../../../services/authServices";
import { useAuth } from "../../../hooks/auth";
import LatoText from "../../../components/LatoText";
import colors from "../../../util/colors";
const NewUser = ({ navigation, back, setErrorMsg }) => {
  const { signIn } = useAuth();
  const route = useRoute(); // This gets the navigation route
  const { type } = route.params;

  const [data, setData] = React.useState({
    otp: "",
    email: "",
    password: "",
    confPassword: "",
    check_textInputChange: false,
    check_passInputChange: false,
    check_confPassInputChange: false,
    isValidOTP: true,
    isValidEmail: true,
    isValidPassword: true,
    secureTextEntry: true,
    passwordMatch: true,
    otpGenerated: false,
  });

  const [timeLeft, setTimeLeft] = useState(null);
  const [activeResend, setActiveResend] = useState(true);

  const timerTick = () => {
    if (activeResend) {
      return;
    }
    if (timeLeft <= 1) {
      setActiveResend(true);
    } else {
      setTimeLeft(timeLeft - 1);
    }
  };

  const timerReset = (targetTime) => {
    setTimeLeft(targetTime);
    setActiveResend(false);
  };

  useEffect(() => {
    let timerID = setInterval(() => timerTick(), 1000);
    return () => clearInterval(timerID);
  });

  const genOTP = async () => {
    if (data.isValidEmail) {
      if (data.email.length == 0) {
        setErrorMsg("Erro: Campo de email não pode estar vazio.");
        return;
      }

      if (activeResend) {
        try {
          setActiveResend(false);
          await generateOTP(data.email);
          setData({
            ...data,
            otpGenerated: true,
            check_textInputChange: false,
          });
        } catch (error) {
          setErrorMsg("Erro: Email não encontrado.");
          setActiveResend(true);
          return;
        }
        timerReset(30);
      }
    }
  };

  const passwordHandle = async () => {
    if (
      data.password.length == 0 ||
      data.password.length == 0 ||
      data.otp.length == 0
    ) {
      setErrorMsg("Erro: Campo(s) de senha não podem estar vazios.");
      return;
    }

    if (data.isValidOTP && data.isValidPassword && data.passwordMatch) {
      try {
        await updateUserPassword(data.email, data.password, data.otp);
        await signIn(data.email, data.password);
      } catch (e) {
        setErrorMsg("Erro: OTP incorreto, confira seu email.");
      }
    }
  };

  const handleValidEmail = (val) => {
    if (validateEmail(val)) {
      setData({
        ...data,
        isValidEmail: true,
      });
    } else {
      setData({
        ...data,
        isValidEmail: false,
      });
    }
  };

  const emailInputChange = (val) => {
    if (validateEmail(val)) {
      setData({
        ...data,
        email: val,
        check_textInputChange: true,
        isValidEmail: true,
      });
    } else {
      setData({
        ...data,
        email: val,
        check_textInputChange: false,
        isValidEmail: false,
      });
    }
  };

  const updateSecureTextEntry = () => {
    setData({
      ...data,
      secureTextEntry: !data.secureTextEntry,
    });
  };

  const otpInputChange = (val) => {
    if (val.trim().length == 6) {
      setData({
        ...data,
        otp: val,
        check_textInputChange: true,
        isValidOTP: true,
      });
    } else {
      setData({
        ...data,
        otp: val,
        check_textInputChange: false,
        isValidOTP: false,
      });
    }
  };

  const handleValidOTP = (val) => {
    if (val.trim().length == 6) {
      setData({
        ...data,
        isValidOTP: true,
      });
    } else {
      setData({
        ...data,
        isValidOTP: false,
      });
    }
  };

  const handlePasswordChange = (val) => {
    if (validatePassword(val)) {
      setData({
        ...data,
        password: val,
        isValidPassword: true,
        check_passInputChange: true,
        passwordMatch:
          data.confPassword != val && data.confPassword.length != 0
            ? false
            : true,
        check_confPassInputChange:
          data.confPassword != val || data.confPassword.length == 0
            ? false
            : true,
      });
    } else {
      setData({
        ...data,
        password: val,
        isValidPassword: false,
        check_passInputChange: false,
        passwordMatch:
          data.confPassword != val && data.confPassword.length != 0
            ? false
            : true,
        check_confPassInputChange:
          data.confPassword != val || data.confPassword.length == 0
            ? false
            : true,
      });
    }
  };

  const handlePasswordConfirm = (val) => {
    if (val == data.password && data.password.length != 0) {
      setData({
        ...data,
        passwordMatch: true,
        confPassword: val,
        check_confPassInputChange: true,
      });
    } else {
      setData({
        ...data,
        passwordMatch: false,
        confPassword: val,
        check_confPassInputChange: false,
      });
    }
  };

  const validateEmail = (str) => {
    const pattern = new RegExp(
      "^[a-zA-Z0-9_+&*-]+(?:\\." +
        "[a-zA-Z0-9_+&*-]+)*@" +
        "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
        "A-Z]{2,7}$"
    );

    if (!str || str.length == 0) {
      return false;
    }

    if (pattern.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (str) => {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$");

    if (!str || str.length < 8) {
      return false;
    }

    if (pattern.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ImageBackground
      source={require("../../../assets/bg.png")}
      style={{ flex: 1, padding: 0 }}
    >
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.voltar}
          onPress={() => navigation.navigate("LoginPage")}
        >
          <Image
            style={styles.arrow}
            source={require("../../../assets/arrow.png")}
          />
        </TouchableOpacity>
        <LatoText style={[styles.title, { color: "white" }]}>
          {type === "Cadastre-se" ? "Nova Conta" : "Esqueceu sua senha?"}
        </LatoText>
        <View style={styles.contentContainer}>
          {data.otpGenerated ? (
            <View>
              <LatoText
                style={[
                  styles.text_footer,
                  {
                    color: "#033A0C",
                    paddingBottom: 8,
                    marginTop: 10,
                    fontWeight: "bold",
                  },
                ]}
              >
                Insira o codigo enviado ao seu email
              </LatoText>
              <View style={styles.action}>
                <TextInput
                  placeholder="******"
                  placeholderTextColor="#666666"
                  style={[
                    styles.otpInput,
                    {
                      color: "#033A0C",
                    },
                  ]}
                  autoCapitalize={"characters"}
                  maxLength={6}
                  autoComplete={Platform.OS === "web" ? "none" : "off"}
                  onChangeText={(val) => otpInputChange(val)}
                  onEndEditing={(e) => handleValidOTP(e.nativeEvent.text)}
                />
                {data.check_textInputChange ? (
                  <Animatable.View animation="bounceIn">
                    <Feather name="check-circle" color="#033A0C" size={25} />
                  </Animatable.View>
                ) : null}
              </View>
              {data.isValidOTP ? null : (
                <Animatable.View animation="fadeInLeft" duration={500}>
                  <LatoText style={styles.errorMsg}>
                    Codigo requer 6 digitos.
                  </LatoText>
                </Animatable.View>
              )}

              <LatoText
                style={[
                  styles.text_footer,
                  {
                    color: "#033A0C",
                    marginTop: 20,
                  },
                ]}
              >
                Digite a senha desejada
              </LatoText>
              <View style={styles.action}>
                <Feather name="lock" color={"#033A0C"} size={20} />
                <TextInput
                  placeholder="********"
                  placeholderTextColor="#666666"
                  secureTextEntry={data.secureTextEntry ? true : false}
                  style={[
                    styles.textInput,
                    {
                      color: "#033A0C",
                    },
                  ]}
                  autoCapitalize="none"
                  onChangeText={(val) => handlePasswordChange(val)}
                />
                {data.check_passInputChange ? (
                  <Animatable.View
                    animation="bounceIn"
                    style={{ marginRight: 10 }}
                  >
                    <Feather name="check-circle" color="#033A0C" size={25} />
                  </Animatable.View>
                ) : null}
                <TouchableOpacity onPress={updateSecureTextEntry}>
                  {data.secureTextEntry ? (
                    <Feather name="eye-off" color="grey" size={20} />
                  ) : (
                    <Feather name="eye" color="grey" size={20} />
                  )}
                </TouchableOpacity>
              </View>
              {data.isValidPassword ? null : (
                <Animatable.View animation="fadeInLeft" duration={500}>
                  <LatoText style={styles.errorMsg}>
                    Senha fraca. Sua senha deve conter no mínimo 8 caracteres,
                    uma letra maiúscula, minúscula e um número.
                  </LatoText>
                </Animatable.View>
              )}

              <LatoText
                style={[
                  styles.text_footer,
                  {
                    color: "#033A0C",
                    marginTop: 15,
                  },
                ]}
              >
                Confirme sua senha
              </LatoText>
              <View style={styles.action}>
                <Feather name="lock" color={"#033A0C"} size={20} />
                <TextInput
                  placeholder="********"
                  placeholderTextColor="#666666"
                  secureTextEntry={data.secureTextEntry ? true : false}
                  style={[
                    styles.textInput,
                    {
                      color: "#033A0C",
                    },
                  ]}
                  autoCapitalize="none"
                  onChangeText={(val) => handlePasswordConfirm(val)}
                />
                {data.check_confPassInputChange ? (
                  <Animatable.View
                    animation="bounceIn"
                    style={{ marginRight: 10 }}
                  >
                    <Feather name="check-circle" color="#033A0C" size={25} />
                  </Animatable.View>
                ) : null}
                <TouchableOpacity onPress={updateSecureTextEntry}>
                  {data.secureTextEntry ? (
                    <Feather name="eye-off" color="grey" size={20} />
                  ) : (
                    <Feather name="eye" color="grey" size={20} />
                  )}
                </TouchableOpacity>
              </View>
              {data.passwordMatch ? null : (
                <Animatable.View animation="fadeInLeft" duration={500}>
                  <LatoText style={styles.errorMsg}>
                    Senhas não correspondentes
                  </LatoText>
                </Animatable.View>
              )}

              <View style={styles.buttons}>
                <TouchableOpacity
                  style={[
                    styles.next,
                    {
                      backgroundColor: "#033A0C",
                      marginTop: 15,
                      // flexDirection: "row",
                    },
                  ]}
                  onPress={passwordHandle}
                >
                  <LatoText
                    bold
                    style={[
                      styles.textNext,
                      {
                        color: "#fff",
                      },
                    ]}
                  >
                    Verificar
                  </LatoText>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={genOTP}
                  disabled={!activeResend}
                  style={[
                    styles.next,
                    {
                      backgroundColor: "#033A0C",
                      marginTop: 15,
                      flexDirection: "row",
                    },
                  ]}
                >
                  <LatoText
                    bold
                    style={[
                      styles.textNext,
                      {
                        color: "#fff",
                      },
                    ]}
                  >
                    Re-Enviar Email
                  </LatoText>
                  {!activeResend ? (
                    <LatoText
                      bold
                      style={[
                        styles.textNext,
                        {
                          color: "#fff",
                        },
                      ]}
                    >
                      {" "}
                      ({timeLeft})
                    </LatoText>
                  ) : null}
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View>
              <LatoText
                style={[
                  styles.text_footer,
                  {
                    color: "#033A0C",
                    paddingBottom: 8,
                    marginTop: 10,
                    fontWeight: "bold",
                  },
                ]}
              >
                Insira seu email
              </LatoText>

              <View style={styles.inputContainer}>
                <View style={styles.action}>
                  <MaterialCommunityIcons
                    name="email-outline"
                    color={"#033A0C"}
                    size={28}
                  />
                  <TextInput
                    placeholder="exemplo@mail.com"
                    placeholderTextColor="#666666"
                    style={[
                      styles.emailInput,
                      {
                        color: "black",
                      },
                    ]}
                    autoCapitalize="none"
                    onChangeText={(val) => emailInputChange(val)}
                    onEndEditing={(e) => handleValidEmail(e.nativeEvent.text)}
                  />
                </View>
                {data.check_textInputChange ? (
                  <Animatable.View
                    animation="bounceIn"
                    style={{ marginRight: 10 }}
                  >
                    <Feather name="check-circle" color="#033A0C" size={25} />
                  </Animatable.View>
                ) : null}
              </View>
              {data.isValidEmail ? null : (
                <Animatable.View animation="fadeInLeft" duration={500}>
                  <LatoText style={styles.errorMsg}>
                    Formato de email invalido.
                  </LatoText>
                </Animatable.View>
              )}

              <View style={styles.button}>
                <TouchableOpacity
                  styles={styles.signIn}
                  onPress={genOTP}
                  style={[
                    styles.next,
                    {
                      backgroundColor: "#033A0C",
                    },
                  ]}
                >
                  <LatoText bold style={[styles.textNext, { color: "#fff" }]}>
                    Enviar
                  </LatoText>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      </View>
    </ImageBackground>
  );
};

export default NewUser;

const styles = StyleSheet.create({
  container: {
    flex: 1, // Ensure the container takes up the full screen
    justifyContent: "center", // Center content vertically
    alignItems: "flex-start", // Center content horizontally
  },
  title: {
    fontSize: 23,
    fontWeight: "bold",
    color: "#fff",
    padding: 20,
    alignSelf: "flex-start", // Align title to the left
    marginTop: 50,
  },
  contentContainer: {
    backgroundColor: "#fff", // Background color for visibility
    width: "100%", // Width of the container
    flex: 1, // Take remaining space
    elevation: 2, // For shadow on Android
    shadowColor: "#000", // Shadow for iOS
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    justifyContent: "flex-start", // Align items at the top
    alignItems: "center", // Center content horizontally
    paddingTop: 25, // Padding inside the container
    paddingBottom: 25, // Padding inside the container
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  footer: {
    flex: 5,
    backgroundColor: "#fff",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  text_header: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 30,
  },
  text_footer: {
    color: "#000",
    fontSize: 18,
  },
  action: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#f2f2f2",
    paddingBottom: 5,
    marginLeft: 5,
  },
  actionError: {
    flexDirection: "row",
    marginTop: 50,
    borderBottomWidth: 1,
    borderBottomColor: "#FF0000",
    paddingBottom: 5,
  },
  textInput: {
    flex: 1,
    paddingLeft: 10,
    paddingVertical: 5,
    marginHorizontal: 10,
    color: "#000",
  },
  emailInput: {
    flex: 1,
    paddingLeft: 15,
    paddingVertical: 10,
    marginHorizontal: 10,
    borderColor: "#d3d3d3",
    fontSize: 15,
    minWidth: 1,
  },
  otpInput: {
    flex: 1,
    paddingVertical: 5,
    color: "#000",
  },
  buttons: {
    flexDirection: "column", // or 'row' depending on your layout
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // or a specific value
    marginTop: 20,
    // zIndex: 1,
  },
  button: {
    marginTop: 20,
    width: 250,
    height: 40,
    alignSelf: "center",
  },
  signIn: {
    height: 40,
    width: "100%",
    backgroundColor: "#033A0C",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  next: {
    width: 250,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  textNext: {
    fontSize: 18,
    fontWeight: "bold",
  },
  errorMsg: {
    color: "#FF0000",
    fontSize: 14,
  },
  backSign: {
    fontSize: 16,
    marginBottom: 20,
    fontWeight: "bold",
  },
  confirContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "flex-start",
    marginTop: 10,
    marginLeft: 5,
  },
  enviarTxt: {
    fontSize: 16,
    color: "#033A0C",
    fontWeight: "bold",
    marginTop: 3,
    marginLeft: 28,
    marginBottom: 10,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderColor: "lightgray",
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    marginVertical: 8,
  },
  voltar: {
    // Style for the button
    padding: 20, // Add padding around the button
  },
  arrow: {
    width: 22, // Set appropriate width
    height: 20, // Set appropriate height
    resizeMode: "contain", // Adjust how the image should be resized
  },
});
