import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import * as Linking from 'expo-linking';
import { useAuth } from './auth';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';

import io from "socket.io-client";
import { useConference } from './conference';

const ConnectionsContext = React.createContext({
  loading: false,
  pendingConnection: null,
  connect: async () => {},
});

const socketEndpoint = Constants.expoConfig.extra && Constants.expoConfig.extra.api ? Constants.expoConfig.extra.api
 : "https://brasa-api-staging.herokuapp.com";

export function ConnectionsProvider({ children }) {
  const [socketClient, setSocketClient] = useState(null);
  const {user} = useAuth();
  const [loading, setLoading] = useState(false);
  const [pendingConnection, setPendingConnection] = useState(null);
  const navigation = useNavigation();

  useEffect(() => {
    let socket;
    if(socketClient){
      console.log("Connection already exists");
      return;
    }
    if(!user){
      console.log("User not logged in");
      return;
    }
    console.log("Initializing socket");
    setLoading(true);
    try {
      socket = io.connect(socketEndpoint, {
        query: {
            auth2token: user.auth.token
        }, transports: ['websocket']
      });

      socket.on('connect', (data) => {
        console.log('connected');
        setSocketClient(socket);
        setLoading(false);
      });

      // Deal with authentical errors.
      socket.on('error', (data) => {
          console.log('error', data);
          setLoading(false);
      })
      
      // Deal with exceptions.
      socket.on('exception', (data) => {
          console.log('exception');
          if(data.type === 'alreadyExists') {
            navigation.navigate('Main', {screen: "ContactsBook", params: {autoOpenContact: data.payload.otherParticipant.user}});
            window.history.replaceState(null, "BRASA", "/")
          }
          else{
            console.log('not already exists, see data', data)
          }
      });
      
      socket.on('message', (data) => {
        if(data.type === 'connectionStatus') {
          if(data.status === 'connected') {
            navigation.navigate('Main', { screen: "ContactsBook",  params:{autoOpenContact: data.payload.participants.find(participant => participant.userId  !== user.id).user}});
            window.history.replaceState(null, "BRASA", "/")
          }else if(data.status === "waiting") {
            navigation.navigate('PendingConnection');
            window.history.replaceState(null, "BRASA", "/")
          }
        }
      });
        
    } catch (error) {
        console.error(error)
        setLoading(false);
    }
    return () => {
      if(socket) {
        socket.disconnect();
        socket.removeAllListeners();
      }
      setPendingConnection(null)
    };
  }, []);

  const connect = async (idToConnect) => {
    try { 
      if(socketClient) {
        if(pendingConnection && pendingConnection === idToConnect) {
          return;
        }
        socketClient.emit('connectParticipants', {
          participantId: idToConnect,
        });
        setPendingConnection(idToConnect);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const value = useMemo(
    () => ({ connect, loading, pendingConnection, socketClient }),
    [ loading, connect, pendingConnection, socketClient]
  );

  return <ConnectionsContext.Provider value={value}>{children}</ConnectionsContext.Provider>;
}

export function useConnections() {
  const context = React.useContext(ConnectionsContext);

  if (!context) {
    throw new Error('useConnections must be used within a ConnectionsProvider');
  }

  return context;
}