
import React, { useEffect, useMemo, useReducer, useRef } from 'react'
import { Pressable, StyleSheet, View, Text } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import DropShadow from "react-native-drop-shadow";
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'

const AnimatedTabBar = ({ state: { index: activeIndex, routes }, navigation, descriptors }) => {
    const { bottom } = useSafeAreaInsets();

    const reducer = (state, action) => {
        return [...state, { x: action.x, index: action.index }];
    };

    const [layout, dispatch] = useReducer(reducer, []);

    const handleLayout = (event, index) => {
        dispatch({ x: event.nativeEvent.layout.x, index });
    };

    const xOffset = useMemo(() => {
        if (layout.length !== routes.length)
            return 0;
        return [...layout].find(({ index }) => index === activeIndex).x - 20;
    }, [activeIndex, layout]);

    return (
        <DropShadow style={styles.shadowProp}>
            <View style={[styles.tabBar, { paddingBottom: bottom }]}>
                <View style={styles.tabBarContainer}>
                    {routes.map((route, index) => {
                        const active = index === activeIndex;
                        const { options } = descriptors[route.key];
                        return (
                        <TabBarComponent 
                            key={route.key} 
                            active={active} 
                            options={options} 
                            onLayout={(e) => handleLayout(e, index)} 
                            onPress={() => {
                                navigation.navigate(route.name);
                            }}
                        />
                        );
                    })}
                </View>
            </View>
        </DropShadow>
    );
};

const TabBarComponent = ({ active, options, onLayout, onPress }) => {
  // --
  const ref = useRef(null);
  useEffect(() => {
      if (active && ref?.current) {
          ref.current.play();
      }
  }, [active]);
  
  const animatedIconContainerStyles = useAnimatedStyle(() => {
      return {
          opacity: withTiming(active ? 0.8 : 0.4, { duration: 250 })
      };
  });
  
  return (
    <Pressable onPress={onPress} onLayout={onLayout} style={styles.component}>
        <Animated.View style={[styles.iconContainer, animatedIconContainerStyles]}>
        {options.tabBarIcon ? options.tabBarIcon({ ref }) : <Text>?</Text>}
        </Animated.View>
    </Pressable>
  );
};


const styles = StyleSheet.create({
  tabBar: {
      backgroundColor: '#fff',
  },
  activeBackground: {
      position: 'absolute',
  },
  tabBarContainer: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
  },
  component: {
      height: 70,
      width: 70,
      marginTop: -5,
  },
  iconContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center'
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOpacity: 0.4,
    shadowRadius: 8,
  },
});

export default AnimatedTabBar;