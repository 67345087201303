import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import AllInitiatives from "./AllInitiatives";
import UserInitiatives from "./UserInitiatives";

const InitiativesPersonal = () => {
  const [showUserInitiatives, setShowUserInitiatives] = useState(true);

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          {showUserInitiatives ? <UserInitiatives /> : <AllInitiatives />}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#FFFFFF",
    width: 339,
  },
  contentContainer: {
    flex: 1,
    minHeight: 200,
    maxHeight: 350,
    overflow: "hidden",
  },
  scrollContainer: {
    flexGrow: 1,
  },
  title: {
    color: "#005248",
    fontSize: 20,
    fontWeight: "900",
    marginBottom: 16,
    textAlign: "center",
  },
});

export default InitiativesPersonal;
