//@ts-nocheck
import React, { useEffect, useState } from "react";
import { View, Platform } from 'react-native';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import csvjson from '../assets/csvjson.json';


const CourseAutocomplete = ({ placeholder, onSelect, inputSearchString = "", zIndex = 1 }) => {
    const [majors, setMajors] = useState([]);

    const formatResult = (item) => {
        return (
            <>
                <span style={{ textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    useEffect(() => {
        const majors = [...csvjson].map((major, i) => {
            return {
                id: i,
                name: major['Major'],
            }
        })
        setMajors(majors)
    }, []);

    return <View style={{
        marginVertical: 5,
        width: '100%',
        zIndex: zIndex
    }} >
        <ReactSearchAutocomplete
            formatResult={formatResult}
            items={majors}
            onSelect={onSelect}
            onClear={onSelect}
            showIcon={true}
            styling={{
                height: "40px",
                border: "1px solid black",
                borderRadius: "4px",
                boxShadow: "none",
                clearIconMargin: "3px 8px 0 0",
                position: "relative"
            }}
            placeholder={placeholder}
            inputSearchString={inputSearchString}
            maxResults={4}
            autoFocus={false}
        />

    </View>;
};


export default CourseAutocomplete









