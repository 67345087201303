import React from "react";
import ProfileEditInfo from "../components/ProfileEditInfo";
import {ProfileEditPicker, ProfileEditPickerMultiple} from "../components/ProfileEditPicker";
import DegreeDropdown from "../components/DegreeDropDown";
import ProfileUniSearch from "../components/ProfileUniSearch";
import CourseAutocomplete from "../components/CourseAutocomplete";
import ProfileEditDate from "../components/ProfileEditDate";

const ProfileFields = (form, setForm, forceRefresh=false, setForceRefresh=null) => {

    const updateForm = (key, value) => {
        setForm(oldState => ({
            ...oldState,
            [key]: value,
        }));
    };

    const listaDeNacionaliades = [
        {value: "7304752e-9966-413e-b95f-8855e0661038", label: "Albanesa"},
        {value: "d50db7ac-bc08-4204-b61b-a000bd4ce298", label: "Alemã"},
        {value: "c211af42-13d5-429f-91ad-fcb0da4f7f13", label: "Andorrana"},
        {value: "2bf39546-8f74-45d9-9fb8-ec15bce54902", label: "Angolana"},
        {value: "009ad698-c3db-4be1-8336-53f70d983187", label: "Anguilana"},
        {value: "a29315a1-5ed5-4b3c-b1b9-1c941649aa9c", label: "Antiguana"},
        {value: "f2962bca-c295-425d-b913-e5ccc37f9e05", label: "Antilhana"},
        {value: "374e7307-0407-495a-a0ca-8b185f4ade25", label: "Argelina"},
        {value: "09403551-d241-4334-9abd-2eafd9cb546e", label: "Argentina"},
        {value: "1ba454bd-a9ab-48bb-b405-81bb71054593", label: "Arménia"},
        {value: "1e680d96-f6c3-40cb-b549-9552300a38de", label: "Arubana"},
        {value: "5f5286f4-8d94-40a8-957a-a9233c2027e7", label: "Australiana"},
        {value: "d463caca-3b6d-4348-a18b-16dcc8daf935", label: "Austríaca"},
        {value: "044b8c85-7189-42d8-a751-71f02e905d6d", label: "Azeri"},
        {value: "9b214847-da8b-4bf8-97fc-84c838fc5b8f", label: "Baamiana"},
        {value: "d421c7f9-9ebb-483f-b99a-2f2409997420", label: "Bangladechiana"},
        {value: "ab31e7bf-1666-4cb4-a97e-54ee89876f7f", label: "Barbadense"},
        {value: "d3763820-75f9-4225-9249-c0484f50409e", label: "Barenita"},
        {value: "2e00cac6-df2e-42a0-b303-59475ac559e4", label: "Belga"},
        {value: "b1319283-5511-4f6e-8646-c09c1d722729", label: "Belizenha"},
        {value: "daedfcca-4b53-48d8-ae8b-7234c07bcd3a", label: "Beninesea"},
        {value: "3868b244-a6c6-43e8-b1f1-23892907a106", label: "Bermudense"},
        {value: "eb9f6eb0-1195-4b71-8bd8-655014f41fbd", label: "Bielorrussa"},
        {value: "cb9e5bb6-341a-449d-81c1-27619fdf9e82", label: "Birmanesa"},
        {value: "594dd28a-268f-4770-8f26-2986c16388f4", label: "Boliviana"},
        {value: "a9477241-56d0-4aca-bdc4-e96fed5d3cf6", label: "Botsuana"},
        {value: "d8dcd8ae-f31a-4e54-92c7-06f71a9505c6", label: "Brasileira"},
        {value: "c747cb1d-9fbc-45b8-b192-df9f6afec66f", label: "Britânica"},
        {value: "390b9879-44af-45b1-aefc-3919fa4baa68", label: "Bruneína"},
        {value: "9784830a-bc63-4d4d-984e-83e24c34b5a5", label: "Burquina"},
        {value: "164eedab-c286-49ec-8e01-54966bfa2f90", label: "Burundiana"},
        {value: "fd969a08-d255-48f5-8389-d61c7c4dba21", label: "Butanesa"},
        {value: "6276fcfe-2bcf-40bf-911e-aa22a9c3e6cb", label: "Bósnia"},
        {value: "8f2a6ea2-cb7b-483f-8718-56734aa18038", label: "Búlgara"},
        {value: "a0c853db-1580-4069-936d-4198e0878138", label: "Cabo-verdiana"},
        {value: "502bfa54-ea0f-470e-ba6e-bc9cc0446654", label: "Caimanesa"},
        {value: "03bf9b7e-328a-4f30-8410-42bc5b18bf32", label: "Camaronesa"},
        {value: "d0295ad2-98da-4878-9b23-08eb092a03f9", label: "Cambojana"},
        {value: "9ebccffe-f9d2-40e7-bc2e-2f850b62514b", label: "Canadiana"},
        {value: "3fb157da-d3fe-4b9e-969e-f9e34fd66b39", label: "Catariana"},
        {value: "3c36c61e-0097-4c16-8bab-257d7a282b14", label: "Cazaque"},
        {value: "11bbdd5d-fcbc-4524-b570-7d026ec21207", label: "Centro-africana"},
        {value: "6f51986f-50a1-4b08-814e-b8381dfdc5ca", label: "Chadiana"},
        {value: "1bf807e2-cb11-4dea-82c4-b1dcf7c4c8ad", label: "Chilena"},
        {value: "2a76da61-89fb-4ea6-8db4-cdfc23cbedd6", label: "China"},
        {value: "ad4795f3-b62c-4061-95d2-cfd9d197e5e9", label: "Cingalesa"},
        {value: "70c25c09-59b8-4317-a67f-6b80d7d91624", label: "Cipriota"},
        {value: "1cc512a0-7770-4b8b-af81-d1143788ab00", label: "Colombiana"},
        {value: "e6984d0a-4d57-4984-91f9-88c47e086a9f", label: "Comoriana"},
        {value: "1f8c7585-87fe-4188-a5e8-6b1ab4405458", label: "Congolesa"},
        {value: "6ba9e87d-f131-4518-92ea-07ace6105f9d", label: "Cookense"},
        {value: "77467ee6-cba9-4bd3-a9fd-545951896c0e", label: "Costarriquenha"},
        {value: "d71096c1-b5c3-492d-910b-4ba96004ee04", label: "Croata"},
        {value: "4b6b24ad-9078-4dae-84b3-8b923bfadf11", label: "Cubana"},
        {value: "f7ce3e71-50e0-442f-9fff-3128ae84aba2", label: "Curaçauense"},
        {value: "b7995b4a-5224-44c0-afc6-1e81f6b52382", label: "Dinamarquesa"},
        {value: "44aa05e3-f34d-4e46-bba5-c684b3c43530", label: "Dominicana"},
        {value: "8df995a7-2b2d-45bf-ad98-965a43f7be3a", label: "Dominiqua"},
        {value: "0212ddb3-610c-4273-b9f1-07b9c8258f89", label: "Egípcia"},
        {value: "f443acf2-2fd8-4626-beda-e2dedf60e501", label: "Emiradense"},
        {value: "c037fbfc-f7e4-4986-adc1-143ab0fe781a", label: "Equatoriana"},
        {value: "1a7efab5-1742-4431-93f8-da561bc09c1b", label: "Eritreia"},
        {value: "8b38e95d-300a-4c94-ae88-f7a9ddfc8602", label: "Escocesa"},
        {value: "16e6551e-41f5-41cb-a00f-82cd78f3c37b", label: "Eslovaca"},
        {value: "20bbf770-575f-49cd-8826-d0de9618220d", label: "Eslovena"},
        {value: "c36c1707-8d19-438e-ac4f-0bca990640ea", label: "Espanhola"},
        {value: "fc18afa5-69b2-4066-b334-c7b60dfc7c96", label: "Essuatinianoa"},
        {value: "1f59974c-a9cf-494d-a99c-fa85918fc214", label: "Estoniana"},
        {value: "27c86f38-4ac3-4e35-bb45-5ea2b4eb0fd9", label: "Etíope"},
        {value: "4d16e11b-d801-4a68-a170-71cbc1ee7411", label: "Faroense"},
        {value: "3eb69364-1d87-4533-9de6-435ab821b4cc", label: "Fijiana"},
        {value: "037a0f38-d7e8-40ef-9dd3-eb244287db8b", label: "Filipino"},
        {value: "c03d4ac7-601b-4aec-b89f-86ce1ef2629e", label: "Finlandesa"},
        {value: "561de70c-7414-46da-b803-1e7c3931905e", label: "Francesa"},
        {value: "a70f2453-def5-492c-9fcd-84a2aab1f13d", label: "Gabonesa"},
        {value: "c3b821b7-9a7b-45c8-a995-77127c98d057", label: "Galesa"},
        {value: "17a2e450-1b56-412c-a83d-afc38f2cbfc0", label: "Gambiana"},
        {value: "cfa26a93-3ec2-4264-9f08-376550963f8c", label: "Ganesa"},
        {value: "6ba720e1-6b5f-4271-a561-f9a0f117d5e8", label: "Georgiana"},
        {value: "5ac94b36-c8e5-45be-9ac5-e5b9510288d8", label: "Granadina"},
        {value: "7d4e50c1-3e16-49d2-b25a-98a37f5cca3c", label: "Grega"},
        {value: "396710dc-4cea-4d8e-b4a8-595e6a67d7c8", label: "Guadalupense"},
        {value: "22069066-b883-4cd6-905c-c63014f91703", label: "Guamesa"},
        {value: "32937a0e-d5b5-4f82-b544-6b431e32fc06", label: "Guatemalteca"},
        {value: "80af8207-0f4f-4758-8e57-84b11a01d750", label: "Guianense"},
        {value: "c3a54642-b7b7-4559-af66-e640270dec8c", label: "Guianesa"},
        {value: "e7d238a1-a58c-4622-80ed-a367c7e8eb08", label: "Guineana"},
        {value: "6bba0fd4-37ec-4faa-a798-e6d860e14ab0", label: "Guineense"},
        {value: "b94a5f69-6028-442c-9899-ff7e6a9c65e3", label: "Guinéu-equatoriana"},
        {value: "69d7ab23-ecd1-468a-8b0f-21c72b65c8a2", label: "Haitiana"},
        {value: "224e56b7-4140-4f5b-93db-a03346c2a40f", label: "Honconguesa"},
        {value: "b562bfca-064e-4603-b2fc-a4445a776242", label: "Hondurenha"},
        {value: "dd19b125-9e1f-40ed-8170-cbbb95c4b916", label: "Húngara"},
        {value: "32111e75-3e27-4781-aa33-508246593698", label: "Iemenita"},
        {value: "ab45a34a-9bff-450f-90e0-51298e758c83", label: "Indiana"},
        {value: "361c1b93-1c55-4f5d-b81d-4301110674c7", label: "Indonésia"},
        {value: "ecd922b6-d85b-4a48-a538-911a5b344ed7", label: "Inglesa"},
        {value: "e2ed97f1-51f8-45fb-aaf9-95dfa6203781", label: "Iraniana"},
        {value: "1c164881-0f4b-4d3f-ad84-b04d5ddf2943", label: "Iraquiana"},
        {value: "7ce0bca9-cb64-4ae9-b6b5-4f2580bc0c31", label: "Irlandesa"},
        {value: "1cc497c2-eb6d-4366-bc3e-6fd29397b39f", label: "Islanda"},
        {value: "0d634372-f2dc-42e5-b0f9-626fed0413e0", label: "Israelense"},
        {value: "35bbcaca-c5da-4d7e-afb9-953c18a408ee", label: "Italiana"},
        {value: "f157b448-1d12-4e50-b922-d257e5e80ea1", label: "Jamaicana"},
        {value: "925aded8-234f-4f9d-9ca6-7753faf5a659", label: "Japonesa"},
        {value: "1c02c625-e9bc-41ff-9313-0d1b52f45f00", label: "Jibutiana"},
        {value: "d22317e9-77bf-40f2-b2e3-f66cd3fd3c41", label: "Jordana"},
        {value: "9013b670-c5e0-4b3d-b476-8367590d28eb", label: "Kosovar"},
        {value: "c5b8d56f-e362-4d4b-b9ec-5eaf7f78a8b3", label: "Kuwaitiana"},
        {value: "392af6f0-21b1-4860-b89c-fe842fbc6dde", label: "Laociana"},
        {value: "6ed1d067-65e0-43fb-96dd-0b97f3f83dd0", label: "Lesotiana"},
        {value: "369df488-c5ab-486d-a6b7-7debc1cbd173", label: "Letã"},
        {value: "7c17e5c7-5d2c-47a7-af90-c44b690772e0", label: "Libanesa"},
        {value: "b1e0f4d1-1647-419f-9312-1f8ea3c89cf2", label: "Liberiana"},
        {value: "81cd3450-e41c-4b77-933d-4ad4ee3d0519", label: "Listenstainiana"},
        {value: "44b821e5-a968-4fa1-b846-c23327c20445", label: "Lituana"},
        {value: "a2cdc001-6d8b-4b9b-b98b-68b7c9ad504c", label: "Luxemburguesa"},
        {value: "4898a188-90c8-495b-971f-98a611386350", label: "Líbia"},
        {value: "c4f7665d-aaaf-4bd8-9b21-5a9061d5163d", label: "Macaense"},
        {value: "3efaa16d-3632-4004-9758-c1130368c478", label: "Macedônica"},
        {value: "93d3e405-2b94-432c-87b5-a6f7a6073b4d", label: "Malaia"},
        {value: "61e6ef2b-7023-4c34-b56e-2f171fe7f861", label: "Malauiana"},
        {value: "37685eed-f1c2-4e01-af1d-3a7368876309", label: "Maldiva"},
        {value: "7e45ab35-e7a5-43de-b014-9aebf9032e4e", label: "Malgaxe"},
        {value: "5cdd7caf-c9a7-4332-a416-4488253bd72a", label: "Malinês"},
        {value: "7c17bc18-6775-4b9d-af61-cf14cb8cf656", label: "Maltesa"},
        {value: "9255b2b4-2f7c-4cda-8cfd-0f73dec90ce8", label: "Marfinense"},
        {value: "f45e552a-9a1b-433b-80af-dc2ee1585ba1", label: "Marroquina"},
        {value: "66538c3a-395c-4eee-9d8b-54e10244630c", label: "Martinicana"},
        {value: "472b2514-7ae6-4f63-96ea-13b08155b464", label: "Mauriciana"},
        {value: "9b110a15-5a4a-4fed-9ccc-ae65762bda9f", label: "Mauritana"},
        {value: "de59b6de-bd45-4e30-89f9-bd08f9778fa9", label: "Mexicana"},
        {value: "e1f3cf39-809f-4a9a-9ac7-54444ca4c656", label: "Micronésia"},
        {value: "1bcb0d80-a1e3-445f-aea4-3ad6fc999c10", label: "Afegã"},
        {value: "b65e073c-8117-4cac-a2bc-b08e4b84f0c4", label: "Moldava"},
        {value: "206a1fc4-e472-4489-931b-56b71626a527", label: "Monegasca"},
        {value: "f39614d2-4ee5-4243-8cc6-006900426acc", label: "Mongol"},
        {value: "bc147837-cc10-4b02-a1e5-d8753b081d2f", label: "Monserratense"},
        {value: "d408080a-4e35-4f75-8c83-1bc61feac3fe", label: "Montenegrina"},
        {value: "9f48b654-32a5-478a-9219-0a9eada134c6", label: "Moçambicana"},
        {value: "da7ffa5b-5321-43fc-9793-6fa6de780413", label: "Namibiana"},
        {value: "6b0e9fb6-8689-4542-8df5-9b399696a339", label: "Nauruana"},
        {value: "3fefb4ed-09a9-4c1a-afc2-f22a605941a0", label: "Neerlandesa"},
        {value: "e062eda1-69c5-4bcd-80b6-d8e410061fed", label: "Neocaledónia"},
        {value: "03d34d1b-2e1c-46db-872e-4f4a68283886", label: "Neozelandesa"},
        {value: "ba60bcb7-e39a-44ec-8754-75fe984de064", label: "Nepalesa"},
        {value: "8ae7b0e0-ac81-444e-ad51-d29a0994d1ef", label: "Nicaraguense"},
        {value: "ed172b50-871e-4e69-b597-a9f1d0315737", label: "Nigeriana"},
        {value: "619f5638-ca84-467c-90ad-7f501252c9b0", label: "Nigerina"},
        {value: "347626dc-4c9b-4b84-a2aa-09458a91b2bf", label: "Norte-americana"},
        {value: "33605dcf-0c5e-4489-82ab-fb534251eb54", label: "Norte-coreana"},
        {value: "93cf9101-c44c-4084-96dc-c4558e803b07", label: "Norte-irlandesa"},
        {value: "23e50c92-85d8-4c1d-be81-7c9664b44302", label: "Norueguesa"},
        {value: "74733f7f-3c8d-481b-bba2-69b13a518146", label: "Omanense"},
        {value: "db5260a1-e49a-49fa-be95-369ab9d67f3b", label: "Palauana"},
        {value: "385ceb4e-6941-4051-9514-805e6e218e6d", label: "Palestiniana"},
        {value: "98f4e076-7313-457d-9469-f9e32771df06", label: "Panamenha"},
        {value: "2520626b-1da8-4798-afed-3263801ac22e", label: "Papua"},
        {value: "bd901ec4-d543-42fe-a421-eff1ba609670", label: "Paquistanesa"},
        {value: "5ee363e6-d834-403d-9c9a-918eee052ddc", label: "Paraguaia"},
        {value: "af1ed1c6-cca1-430a-a59d-006388123d36", label: "Peruana"},
        {value: "067593f6-7ab7-463a-a13e-3262d147702b", label: "Polaca"},
        {value: "69265d8a-4245-4a9d-bc4b-2ecd5e3e107d", label: "Polinésia"},
        {value: "3afbb706-5801-403d-9306-ecca06dcbf47", label: "Porto-riquenha"},
        {value: "5e226d53-8240-4d84-a033-c1a61be0c365", label: "Portuguesa"},
        {value: "bfd0c1e3-3581-4f1a-96f5-eef87e8c3c36", label: "Queniana"},
        {value: "d4763466-aec6-48d8-a4e9-ba2dcd700d45", label: "Quirguiz"},
        {value: "3f550a02-ef35-4dc8-b87d-222ff15fed26", label: "Quiribatiana"},
        {value: "d973e862-9c3b-4b92-adb8-3a40f712f8b1", label: "Romena"},
        {value: "d2aba179-f815-4cb7-9849-863fa1b442e0", label: "Ruandesa"},
        {value: "387ad25b-bfb8-44a1-8bc1-2f46a690a4c6", label: "Russa"},
        {value: "977c8dc4-4438-4718-b258-814b3c6a0615", label: "Salomonense"},
        {value: "b34f14b0-fe5b-4c61-9bda-03e685915255", label: "Salvadorenha"},
        {value: "0953998d-b85f-45b4-8d48-ee051954177e", label: "Samoana"},
        {value: "458c597a-5220-410f-9531-bb1d4f6ca88f", label: "Samoense"},
        {value: "c2e95db0-c5e0-4eaa-bb45-7087ad8ad3d7", label: "Santa-luciense"},
        {value: "027100c6-be88-45aa-8ee1-27496a3fdb69", label: "Seichelense"},
        {value: "0d563cbf-c3f1-4c25-afd5-c11873ac72a0", label: "Senegalesa"},
        {value: "a6fdd383-897b-48a9-be7e-f5f84da16234", label: "Serra-leonesa"},
        {value: "aef168ed-3fe5-4eb0-8d56-db40be0c2ea5", label: "Singapurense"},
        {value: "925d493b-40f3-4d2b-b89f-b0951e2d466f", label: "Somali"},
        {value: "4e8a06df-0bf6-414e-b374-f6a1ba163179", label: "Sudanesa"},
        {value: "84d6fc2a-3dbc-42d2-9730-c603536a7508", label: "Sueca"},
        {value: "5e6f70e1-4aa2-4776-abad-0ae8bb68b3fb", label: "Sul-africana"},
        {value: "72bd8c98-8227-443c-a757-8020fad7817d", label: "Sul-coreana"},
        {value: "721936c3-8aa0-4d63-bc7e-af842281f9fb", label: "Surinamesa"},
        {value: "18dd5ecd-3ffc-43a4-96a8-d9f86945714f", label: "Suíça"},
        {value: "55108883-f7b8-46b2-9465-54f31d2f9afd", label: "São-cristovense"},
        {value: "e8a77d2f-ec30-4269-8096-6c2abd68ed7e", label: "São-marinhense"},
        {value: "d9275a31-bdca-4367-bec0-a850c209f7ac", label: "São-martinhense"},
        {value: "eaf526ef-6631-4b7c-97d7-f108486fd68e", label: "São-tomense"},
        {value: "550914d0-d52b-4cdf-bc32-e6249a2611cc", label: "São-vicentina"},
        {value: "88f46dfc-6ae2-4f1c-8fa7-457511787705", label: "Sérvia"},
        {value: "c425f875-c472-4fba-aea7-fe51425df054", label: "Síria"},
        {value: "b67ba45f-a0fe-49da-a02f-b79504b24506", label: "Tailandês"},
        {value: "74597b80-5a57-4931-b265-2b1c65339569", label: "Taiwanesa"},
        {value: "8365b2b0-d6a4-4b72-8b28-2c025e991991", label: "Tajique"},
        {value: "3c7ca77e-509b-443d-a643-82231b24d8bf", label: "Tanzaniana"},
        {value: "c9f0b7f5-449b-4271-b2fb-33c7fd6c15b4", label: "Tcheca"},
        {value: "5d4baee4-1de7-4bc9-a324-c7b21669cec2", label: "Timorense"},
        {value: "6a3c554c-6c43-4f79-9349-8fd28bb268d4", label: "Togolesa"},
        {value: "19416836-4492-4300-b114-271af557cd6e", label: "Tonganesa"},
        {value: "ba29765d-e7ce-442f-bb48-fe5d88023058", label: "Trinitária-tobagense"},
        {value: "4ae6f8be-cc8e-4d6c-bc71-7ebc3f93ff70", label: "Tunisiana"},
        {value: "2473d731-923b-41d2-999f-6f05b13d4b0c", label: "Turca"},
        {value: "ae2aae51-57c3-4930-b0e4-4dc7c2d97e07", label: "Turquemena"},
        {value: "98e8f506-67f4-4ba5-8071-2b3d51eb4747", label: "Turquense"},
        {value: "af9f0a84-80c0-4eac-bb3f-f9ba6dbc73a1", label: "Tuvaluana"},
        {value: "d3c90aa8-80a0-456f-8d28-decbaa645fe5", label: "Ucraniana"},
        {value: "21616a1a-b1a1-4319-a47f-3dd1dc8d0426", label: "Ugandense"},
        {value: "d54517b6-60ee-4a37-a7a8-fa9597ec926f", label: "Uruguaia"},
        {value: "010b01cf-571e-45b6-9952-8d1a32219ff7", label: "Usbeque"},
        {value: "fdfe46af-91a9-45f2-b9d9-ee3f6ddab93a", label: "Vanuatuense"},
        {value: "e490248b-f6f0-4e09-aaa8-8b27c9d48704", label: "Vaticana"},
        {value: "6b84155d-c22d-4a8b-817c-05492b6dc560", label: "Venezuelana"},
        {value: "a37ceeba-4c2d-40f8-892b-85bae29c6755", label: "Vietnamita"},
        {value: "184f20fc-57c3-415a-a71f-616085946566", label: "Virginense"},
        {value: "e70c2e5d-2d7a-425d-be51-e318fb07f1e3", label: "Zambiana"},
        {value: "fbd8aaed-1b50-4b5a-bb50-dd3b721f2273", label: "Zimbabuana"},
        {value: "65e6fca5-eba2-48a7-9390-9ebbdddcba8c", label: "Árabe-saudita"},
    ]

    const updateEducation = (subKey, value, index) => {
        const temp = form;
        temp[index][subKey] = value;
        setForm(temp);
    };

    const birthDate = new Date()
    if (form.birthDate) {
        birthDate.setFullYear(form.birthDate.split("-")[0])
        birthDate.setMonth(form.birthDate.split("-")[1] - 1)
        birthDate.setDate(form.birthDate.split("-")[2])
    }

    return {
        personal: [
            {
                render: <ProfileEditInfo
                    key={"email"}
                    head={"E-mail"}
                    text={form.email}
                    onChange={(value) => updateForm('email', value)}
                    error={!(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ).test(form.email)}
                />
            },
            {
                render: <ProfileEditInfo
                    key={"firstName"}
                    head={"Nome"}
                    text={form.firstName}
                    onChange={(value) => updateForm('firstName', value)}
                />
            },
            {
                render: <ProfileEditInfo
                    key={"lastName"}
                    head={"Sobrenome"}
                    text={form.lastName}
                    onChange={(value) => updateForm('lastName', value)}
                />
            },
            {
                render: <ProfileEditInfo
                    key={"birthPlace"}
                    head={"Local de Nascimento"}
                    text={form.birthPlace}
                    onChange={(value) => updateForm('birthPlace', value)}
                />
            },
            {

                render: <ProfileEditDate
                    key={"birthDate"}
                    head={"Data de Nascimento"}
                    date={birthDate}
                    onChange={(value) => {
                        // console.log(value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate())
                        updateForm('birthDate',(value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate()))
                    }}
                />
            },
            {
                render: <ProfileEditPicker
                    list={[
                        {label: 'Mulher Cis', value: 'Mulher Cis'},
                        {label: 'Mulher Trans', value: 'Mulher Trans'},
                        {label: 'Homem Cis', value: 'Homem Cis'},
                        {label: 'Homem Trans', value: 'Homem Trans'},
                        {label: 'Não-binário', value: 'Não-binário'},
                        {label: 'Outro', value: 'Outro'},
                        {label: 'Prefiro não informar', value: 'Prefiro não informar'},
                    ]}
                    value={form.gender}
                    onChange={(value) => updateForm('gender', value)}
                    label={"Gênero"}
                    zIndex={1}
                />
            },
            {
                render: <ProfileEditPicker
                    list={[
                        {label: 'Heterossexual', value: 'Heterossexual'},
                        {label: 'Bissexual', value: 'Bissexual'},
                        {label: 'Homossexual', value: 'Homossexual'},
                        {label: 'Pansexual', value: 'Pansexual'},
                        {label: 'Assexual', value: 'Assexual'},
                        {label: 'Prefiro não informar', value: 'Prefiro não informar'},
                    ]}
                    value={form.sexualOrientation}
                    onChange={(value) => updateForm('sexualOrientation', value)}
                    label={"Orientação Sexual"}
                    zIndex={1}
                />
            },
            {
                render: <ProfileEditPicker
                    list={[
                        {label: 'Ela/Dela', value: 'Ela/Dela'},
                        {label: 'Ele/Dele', value: 'Ele/Dele'},
                        {label: 'Ile/Dile', value: 'Ile/Dile'},
                        {label: 'Prefiro não informar', value: 'Prefiro não informar'},
                    ]}
                    value={form.pronouns}
                    onChange={(value) => updateForm('pronouns', value)}
                    label={"Pronomes"}
                    zIndex={2}
                />
            },
            {
                render: <ProfileEditPicker
                    list={[
                        {label: 'Amarela', value: 'Amarela'},
                        {label: 'Branca', value: 'Branca'},
                        {label: 'Indígena', value: 'Indigena'},
                        {label: 'Parda', value: 'Parda'},
                        {label: 'Preta', value: 'Preta'},
                        {label: 'Outra', value: 'Outra'},
                        {label: 'Prefiro não informar', value: 'Prefiro não informar'},
                    ]}
                    value={form.colorRace}
                    onChange={(value) => updateForm('colorRace', value)}
                    label={"Cor/Raça"}
                    zIndex={3}
                />
            },
            {
                render: <ProfileEditPickerMultiple
                    key={"nationality"}
                    list={listaDeNacionaliades}
                    value={form.nationality !== undefined ? form.nationality : null}
                    onChange={(values) => {
                        let nationalititesList = []
                        values.forEach((v) => {
                            nationalititesList.push({
                                id: v,
                                nationalityName: listaDeNacionaliades.find((item) => item.value === v).label
                            })
                        })
                        updateForm('nationality', nationalititesList)
                    }
                    }
                    label={"Nacionalidade"}
                    zIndex={3}
                />
            },
            {
                render: <ProfileEditInfo
                    key={"phoneNumberCountryCode"}
                    head={"Código do país"}
                    text={"+" + form.phoneNumberCountryCode}
                    onChange={(value) => updateForm('phoneNumberCountryCode', value.replace("+", ""))}
                />
            },
            {
                render: <ProfileEditInfo
                    key={"phoneNumber"}
                    head={"Número de telefone"}
                    text={form.phoneNumber}
                    onChange={(value) => updateForm('phoneNumber', value)}
                />
            },
            {
                render: <ProfileEditInfo
                    key={"linkedin"}
                    head={"LinkedIn"}
                    text={form.linkedin}
                    onChange={(value) => updateForm('linkedin', value)}
                    error={!(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
                    ).test(form.linkedin)}
                />
            }
        ],
        education: [
            {
                render: (index) => {
                    return (<ProfileEditInfo
                        head={'Ano de graduação*'}
                        text={form[index]?.graduationYear}
                        onChange={(text) => {updateEducation('graduationYear', text, index); setForceRefresh ? setForceRefresh(!forceRefresh) : null}}
                        zIndex={-1}
                        error={!(/^\d{4}$/).test(form[index]?.graduationYear)}
                    />)
                }
            },
            {
                render: (index) => {
                    return (<DegreeDropdown
                        value={form[index]?.type}
                        onSelect={(type) => updateEducation('type', type, index)}
                        zIndex={-1}
                    />)
                }
            },
            {
                render: (index) => {
                    return (<ProfileUniSearch
                        inputSearchStringCountry={form[index]?.country}
                        onSelectCountry={(selected) => {
                            updateEducation('country', selected.name, index)
                        }}
                        inputSearchStringUni={form[index]?.name}
                        onSelectUni={(selected) => updateEducation('name', selected.name, index)}
                    />)
                }
            },
            {
                render: (index) => {
                    return (<CourseAutocomplete
                        onSelect={(text) => updateEducation('primaryFieldOfStudy', text?.name, index)}
                        placeholder={'Curso Major*'}
                        inputSearchString={form[index].primaryFieldOfStudy ?? ""}
                        zIndex={-1}
                    />)
                }
            },
            {
                render: (index) => {
                    return (<CourseAutocomplete
                        onSelect={(text) => updateEducation('fieldOfStudy2', text?.name || null, index)}
                        placeholder={'Curso Minor'}
                        inputSearchString={form[index].fieldOfStudy2 ?? ""}
                        zIndex={-1}
                    />)
                }
            }
        ]
    }
}
export default ProfileFields;
