import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Text } from 'react-native';
import AnimatedTabBar from '../components/AnimatedTabBar';
import Schedule from '../components/Schedule';
import { useConference } from '../hooks/conference';
import ContactsBook from '../pages/contacts/ContactsBook';
import colors from '../util/colors';
import ProfileRouter from './ProfileRouter';
const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

export default function ConferencesRouter() {
  const { currentSelectedConference, selectConference, conferences} = useConference();
  return (
    <Tab.Navigator
    screenOptions={{
      headerStyle: {
        backgroundColor: colors.darkerBlue,
      },
      headerShadowVisible: false,
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      },
      headerShown: false,
    }}
    tabBar={(props) => <AnimatedTabBar {...props}/>}>
        <Tab.Screen name="Schedule" component={Schedule} options={{
            tabBarIcon: () => (<Ionicons name="calendar" color={"white"} size={27}/>),
        }}/>
        <Tab.Screen name="Contatos" component={ContactsBook} options={{
            tabBarIcon: () => (<Ionicons name="book" color={"white"} size={27}/>),
        }}/>
    </Tab.Navigator>)
}