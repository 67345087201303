import React from "react";
import {View} from 'react-native';
import {DatePickerInput, pt, registerTranslation} from "react-native-paper-dates";


const ProfileEditInfo = ({head, date, onChange, enabled = true, zIndex = 1}) => {
    registerTranslation('pt', pt)
    return <View style={{
        marginVertical: 5,
        width: '100%',
        zIndex: zIndex
    }}>
        <DatePickerInput
            inputMode={"start"}
            dense={true}
            locale={'pt'}
            activeOutlineColor={"#0072b1"}
            inputEnabled={enabled}
            label={head}
            mode={'outlined'}
            onChange={onChange}
            value={date}
        />
    </View>;
};


export default ProfileEditInfo