import React from "react";
import { ImageBackground, View } from "react-native";
import summitBg from "../../assets/summitBg.png";
import LatoText from "../../components/LatoText";
import AnimatedLottieView from "lottie-react-native";
import useImage from "../../hooks/image";

const PendingConnectionPage: React.FC = () => {
  const { loading: bgL, error: bgE, image: bg } = useImage("bg.png");
  return (
    <ImageBackground
      source={bg}
      style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
    >
      <View style={{}}>
        <LatoText
          style={{
            color: "#000000",
            fontSize: 24,
            marginBottom: 32,
            textAlign: "center",
          }}
        >
          Aguardando outro participante... Peça para a outra pessoa scanear seu
          QRCode
        </LatoText>
        <AnimatedLottieView
          style={{ transform: [{ scale: 0.8 }] }}
          source={require("../../assets/hourglass.json")}
          autoPlay
          loop
          colorFilters={[
            {
              keypath: "*", // This targets all layers. Adjust as needed.
              color: "#fff",
            },
          ]}
        />
      </View>
    </ImageBackground>
  );
};

export default PendingConnectionPage;
