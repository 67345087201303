import React, { useState } from "react";
import {
  View,
  ScrollView,
  ImageBackground,
  Animated,
  TouchableOpacity,
} from "react-native";
import { StyleSheet } from "react-native";
import LatoText from "../../components/LatoText";
import InitiativesBook from "./InitiativesBook"; // Todas Iniciativas
import InitiativesPersonal from "./personal/InitiativesPersonal"; // Minhas Iniciativas
// import BackToMenu from "../../components/BackToMenu";
import useImage from "../../hooks/image.js";
import HeaderRight from "../../components/HeaderRight";
import SanduicheMenu from "../sanduicheMenu/SanduicheMenu";
import { useNavigation } from "@react-navigation/native";

const InitiativesBoard = () => {
  const [selectedPage, setSelectedPage] = useState("Book"); // Começar com "Todas Iniciativas"
  const { image: bg } = useImage("bg.png");
  const [menuVisible, setMenuVisible] = useState(false);
  const navigation = useNavigation();

  return (
    <View style={{ flex: 1 }}>
      <HeaderRight onPress={() => setMenuVisible(true)} />
      {/* <BackToMenu /> */}
      <ImageBackground
        source={bg}
        style={{ flex: 1, alignItems: "center", justifyContent: "flex-start" }}
      >
        <LatoText style={styles.title}>Iniciativas</LatoText>
        <View style={styles.contentContainer}>
          <View style={styles.buttonContainer}>
            {/* Botão para acessar Todas Iniciativas */}
            <TouchableOpacity onPress={() => setSelectedPage("Book")}>
              <LatoText
                style={[
                  selectedPage === "Book" ? styles.selectedButton : null,
                  styles.botoes,
                ]}
              >
                Todas Iniciativas
              </LatoText>
            </TouchableOpacity>
            {/* Botão para acessar Minhas Iniciativas */}
            <TouchableOpacity onPress={() => setSelectedPage("Personal")}>
              <LatoText
                style={[
                  selectedPage === "Personal" ? styles.selectedButton : null,
                  styles.botoes,
                ]}
              >
                Minhas Iniciativas
              </LatoText>
            </TouchableOpacity>
          </View>
          <Animated.View style={styles.footer}>
            {selectedPage === "Personal" ? (
              <InitiativesPersonal /> // Componente de Minhas Iniciativas
            ) : (
              <InitiativesBook /> // Componente de Todas Iniciativas
            )}
          </Animated.View>
        </View>
      </ImageBackground>

      {/* Menu Sanduiche para navegação */}
      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </View>
  );
};

export default InitiativesBoard;

const styles = StyleSheet.create({
  title: {
    fontSize: 27,
    fontWeight: "bold",
    color: "#fff",
    padding: 20,
    alignSelf: "flex-start", // Align title to the left
    marginTop: 90, // Add margin to push the title down
  },
  contentContainer: {
    backgroundColor: "#fff", // Background color for visibility
    width: "100%", // Width of the container
    flex: 1, // Take remaining space
    elevation: 2, // For shadow on Android
    shadowColor: "#000", // Shadow for iOS
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    justifyContent: "flex-start", // Align items at the top
    alignItems: "center", // Center content horizontally
    padding: 25, // Padding inside the container
  },
  botoes: {
    padding: 10,
    borderRadius: 30,
    color: "#FFFFFF",
    textAlign: "center",
    minWidth: 130,
    fontSize: 14,
    lineHeight: "16.8px",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedButton: {
    backgroundColor: "#7AB241",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    padding: 3,
    backgroundColor: "#033A0C",
    marginBottom: 10, // Add some space below the buttons
  },
  footer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1, // Allow the footer to take remaining space
  },
});
