//@ts-nocheck
import React, {useEffect, useState} from "react";
import {View, Platform} from 'react-native';
import {ReactSearchAutocomplete} from 'react-search-autocomplete';
import csvjson from '../assets/csvjson.json';
import DropDownPicker from "react-native-dropdown-picker";


const DegreeDropdown = ({onSelect, value = "", zIndex = 1}) => {
    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState([
        {label: 'Ensino Médio', value: 'Ensino médio'},
        {label: 'Graduação', value: 'Graduação'},
        {label: 'Pós-Graduação', value: 'Pós graduação'}
    ]);
    const [currValue, setValue] = useState(value)

    return <View style={{
        marginVertical: 5,
        width: '100%',
        zIndex: zIndex
    }}>
        <DropDownPicker
            open={open}
            value={currValue}
            items={items}
            setOpen={setOpen}
            setValue={setValue}
            onChangeValue={onSelect}
            setItems={setItems}
            style={{
                margin: 8,
                backgroundColor: 'white',
                borderBottomColor: 'black',
                borderBottomWidth: 1,
                borderColor: 'white'
            }}
            placeholder="Tipo de Educação*"
            zIndex={6}
        />
    </View>;
};


export default DegreeDropdown









