import React from 'react';
import { Text, TextProps, StyleSheet } from 'react-native';

const getFontFamily = (bold: boolean, italic: boolean, black: boolean, light: boolean) => {
  if(light){
    if(italic){
      return 'Lato_300Light_Italic';
    }
    return 'Lato_300Light';
  }else if(black){
    if(italic){
      return 'Lato_900Italic';
    }
    return 'Lato_900Black';
  }else if(bold){
    if(italic){
      return 'Lato_700Bold_Italic';
    }
    return 'Lato_700Bold';
  }else if(black){
    if(italic){
      return 'Lato_900Italic';
    }
    return 'Lato_900Black';
  } else {
    if(italic){
      return 'Lato_400Regular_Italic';
    }
    return 'Lato_400Regular';
  }
}

interface Props extends TextProps{
  bold?: boolean;
  italic?: boolean;
  black?: boolean;
  light?: boolean;
};
const LatoText: React.FC<Props> = ({children, bold, italic, black, light, style}) => {
  const styleText = StyleSheet.compose(style, {fontFamily: getFontFamily(bold, italic, black, light)});
  return <Text style={styleText}>{children}</Text>;
}

export default LatoText;