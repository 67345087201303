//@ts-nocheck
import React, {useEffect, useState} from "react";
import { View } from 'react-native';
import ProfileEditSearch from "./ProfileEditSearch";
import getUniversities from "../services/apiPlaceholder";
import api from "../util/axios";


const ProfileUniSearch = ({inputSearchStringCountry, onSelectCountry, inputSearchStringUni, onSelectUni}) => {
    const [unis, setUnis] = useState([]);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        fetch('https://restcountries.com/v3.1/all')
            .then((response) => response.json())
            .then((data) => {
                const countries_dict = data.map((country) => ({
                    name: country['name']['common'],
                    id: country['cca2']
                }));
                setCountries(countries_dict);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    useEffect(() => {
        const current = countries.find((c) => c.name === inputSearchStringCountry)
        if(current) {
            getUnis(current)
        }
    }, [countries])

    const getUnis = (country) => {
        api.get('/utils/universities?country=' + country['name'].replace(" ", "%20"))
            .then((res) => {
                const unis_dict = [...res.data].map((uni) => ({ name: uni['name'] }));
                setUnis(unis_dict);
            }).catch((err) => {
                console.log(err)
        })
    }
  
  return <View  style={{
      marginVertical: 5,
      zIndex: 5,
      width: '100%'
    }} >
        <ProfileEditSearch
                placeholder="País da universidade*"
                items = {countries}
                inputSearchString={inputSearchStringCountry}
                onSelect = {(item) => {onSelectCountry(item); getUnis(item)}}
                zIndex = {4}
        />
        <ProfileEditSearch
                placeholder="Universidade*"
                items = {unis}
                inputSearchString = {inputSearchStringUni}
                onSelect = {onSelectUni}
                zIndex = {3}
        />
    </View>;
};


export default ProfileUniSearch