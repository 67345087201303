import React, { useEffect, useState } from "react";
import {
  Image,
  FlatList,
  ImageBackground,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import getSchedule from "../conferences/schedules";
import { useConference } from "../hooks/conference";
import useGeneralizeColor from "../hooks/generalizeColor";
import * as Animatable from "react-native-animatable";
import {
  ISchedule,
  ScheduleTime,
  ConfEventColors,
} from "../interfaces/ISchedule";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import IInitiative from "../interfaces/IInitiative";
import LatoText from "./LatoText";
import colors from "../util/colors";
import useImage from "../hooks/image";
// import BackToMenu from "./BackToMenu";
import HeaderRight from "./HeaderRight";
import SanduicheMenu from "../pages/sanduicheMenu/SanduicheMenu";

const onClick = (navigation: any, initiative: IInitiative) => {
  navigation.navigate("InitiativesBoard", {
    autoOpenInitiative: {
      initiative: initiative,
    } as { initiative: IInitiative },
  });
};

const renderTime = (
  navigation: NavigationProp<any>,
  time: ScheduleTime,
  currentColor: string // Pass the currentColor to renderTime
) => {
  return (
    <View
      key={`${time.time}-${time.events.length}`}
      style={styles.itemContainer}
    >
      {time.events.map((event, index) => {
        if (event.initiative) {
          // Clickable events
          return (
            <TouchableOpacity
              style={styles.eventButton}
              key={event.name}
              onPress={() => onClick(navigation, event.initiative)}
            >
              <View
                style={[
                  styles.eventButtonContainer,
                  { backgroundColor: currentColor },
                ]}
              >
                <LatoText style={styles.eventTime}>{time.time}</LatoText>
                <LatoText style={styles.eventName}>
                  {event.name.toUpperCase()}
                </LatoText>
              </View>
            </TouchableOpacity>
          );
        }
        // Non-clickable events
        return (
          <View key={event.name}>
            <LatoText style={styles.eventTime}>{time.time}</LatoText>
            <LatoText style={styles.eventNameNoLink}>{event.name}</LatoText>
          </View>
        );
      })}
    </View>
  );
};

const Schedule: React.FC<any> = () => {
  const [selectedDay, setSelectedDay] = useState(0);
  const { currentSelectedConferenceId } = useConference();
  const [schedule, setSchedule] = useState([]);
  const navigation = useNavigation();
  const { conferenceColors } = useGeneralizeColor();
  const [menuVisible, setMenuVisible] = useState(false);
  const { loading: bgL, error: bgE, image: bg } = useImage("bg.png");
  const { image: brasaWhiteS, loading: brasaWhiteSLoading } =
    useImage("branco.png");
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Maio",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  useEffect(() => {
    if (currentSelectedConferenceId) {
      getSchedule(currentSelectedConferenceId).then((res) => setSchedule(res));
    }
  }, [currentSelectedConferenceId]);

  // Define colors for the days
  const colorsForDays = ["#5D9F78", "#7AB241"];
  const currentColor = colorsForDays[selectedDay];

  return (
    <View style={{ flex: 1 }}>
      <ImageBackground
        source={bg}
        style={{ flex: 1, justifyContent: "flex-start" }}
      >
        {/* <BackToMenu /> */}
        <HeaderRight onPress={() => setMenuVisible(true)} />
        <SafeAreaView style={{ flex: 0 }} />
        <StatusBar backgroundColor="#fff" barStyle="light-content" />

        {/* Image at the top, centered */}
        <View style={styles.logoContainer}>
          <Image source={brasaWhiteS} style={styles.logo} />
        </View>

        {/* Day Selection Section */}
        <View style={styles.header}>
          <View style={styles.daySelectionContainer}>
            {schedule.length > 1
              ? schedule.map((day, index: number) => (
                  <TouchableOpacity
                    key={day.day}
                    style={[
                      styles.daySelectionButton,
                      index === selectedDay
                        ? styles.daySelected
                        : styles.dayNotSelected,
                    ]}
                    onPress={() => setSelectedDay(index)}
                  >
                    <LatoText
                      style={{
                        ...styles.monthText,
                        color: index === selectedDay ? currentColor : "#808080",
                      }}
                    >
                      {`Dia ${index + 1} (${months[day.month]} ${day.day})`}
                    </LatoText>
                  </TouchableOpacity>
                ))
              : null}
          </View>
          <View
            style={{
              ...styles.lineBelowDays,
              backgroundColor: currentColor,
            }}
          />
        </View>
        <Animatable.View style={[styles.content]} animation="fadeInUpBig">
          <FlatList
            data={schedule[selectedDay] ? schedule[selectedDay].times : []}
            renderItem={
              ({ item }) => renderTime(navigation, item, currentColor) // Pass currentColor to renderTime
            }
            keyExtractor={(item, index) => `${item.time}-${index}`}
            showsVerticalScrollIndicator={false}
          />
        </Animatable.View>
      </ImageBackground>
      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen: never) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 35,
    marginBottom: 10,
  },
  logo: {
    width: 100, // Medium-large size
    height: 100,
    resizeMode: "contain", // Keep aspect ratio
  },
  header: {
    alignItems: "center",
    marginBottom: 32,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    fontSize: 28, // Reduced font size for title
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  daySelectionContainer: {
    flexDirection: "row", // Align days horizontally
    marginTop: 32,
    justifyContent: "space-around",
    width: "100%",
  },
  daySelectionButton: {
    alignItems: "center",
  },
  daySelected: {
    borderBottomColor: "#5D9F78", // Color of the line below the selected day
  },
  dayNotSelected: {
    borderBottomColor: "#808080", // Color of the line below the not selected day
  },
  monthText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  lineBelowDays: {
    width: "100%",
    height: 2,
    marginTop: 8,
  },
  content: {
    flex: 2,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 30,
  },
  itemContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 24,
  },
  eventButton: {
    alignItems: "flex-start",
    marginBottom: 8,
  },
  eventButtonContainer: {
    borderRadius: 10, // Less rounded
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  eventName: {
    fontSize: 20, // Font size for event names
    fontWeight: "bold", // Event names in bold
    flexWrap: "wrap",
    color: "#FFFFFF",
  },
  eventNameNoLink: {
    fontSize: 18, // Font size for non-clickable events
    fontWeight: "bold", // Non-clickable event names in bold
    flexWrap: "wrap",
    color: "#FFFFFF",
  },
  eventTime: {
    color: "#FFFFFF",
    fontSize: 18,
    marginBottom: 4, // Space between time and event name
  },
});

export default Schedule;
