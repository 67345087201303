import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { Button, Card, IconButton, Snackbar } from "react-native-paper";
import ProfileEditInfo from "../../../components/ProfileEditInfo";
import ProfileUniSearch from "../../../components/ProfileUniSearch";
import CourseAutocomplete from "../../../components/CourseAutocomplete";
import DegreeDropdown from "../../../components/DegreeDropDown";
import { useRoute } from "@react-navigation/native";
import ProfileFields from "../../../enums/ProfileFields";
import ProfileEditUniModal from "../../../components/ProfileEditUniModal";
import api from "../../../util/axios";
import colors from "../../../util/colors";
import LatoText from "../../../components/LatoText";
import ConfirmationModal from "./ConfirmationModal";

const EducationProfile = ({ setSave }) => {
  const route = useRoute();
  const [education, setEducation] = useState(
    route.params?.info?.education ?? []
  );
  const [sOpen, setSOpen] = useState([false, "", ""]); // [visibility, message, snackbar color]
  const [openModal, setOpenModal] = React.useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const profileFields = ProfileFields(
    education,
    setEducation,
    forceRefresh,
    setForceRefresh
  ).education;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newEducation, setNewEducation] = useState([{}]);
  const addNewProfileFields = ProfileFields(
    newEducation,
    setNewEducation
  ).education;

  const addNewSubmit = () => {
    setEducation([...education, ...newEducation]);
    setNewEducation([{}]);
    setOpenModal(false);
  };

  const submitEducation = () => {
    api
      .put(`/webapp/user`, { education })
      .then((res) => {
        setIsModalOpen(true);
      })
      .catch((err) => {
        setSOpen([
          true,
          "Existem campos com erros de preenchimento ou vazios",
          "#df4759",
        ]);
      })
      .finally(() => {
        setSave(false);
      });
  };

  return (
    <>
      <ConfirmationModal visible={isModalOpen} setVisible={setIsModalOpen} />
      <ProfileEditUniModal
        visible={openModal}
        setVisible={setOpenModal}
        profileFields={addNewProfileFields}
        handleSubmit={addNewSubmit}
      />
      {education.map((item, index) => (
        <View key={item.id}>
          <Card
            elevation={2}
            style={{ borderRadius: 10, padding: 10, marginVertical: 10 }}
          >
            <Card.Content>
              {profileFields.map((item, id) => {
                if (item.hasOwnProperty("render")) {
                  return (
                    <View key={id} style={{ zIndex: 100 - id }}>
                      {item.render(index)}
                    </View>
                  );
                }
              })}
            </Card.Content>
            <Card.Actions style={{ flexDirection: "row-reverse", zIndex: -1 }}>
              <IconButton
                onPress={() => {
                  const temp = education;
                  temp.splice(index, 1);
                  setEducation(temp);
                  submitEducation();
                }}
                icon={"trash-can"}
                color={"#d34c4c"}
                size={30}
              />
            </Card.Actions>
          </Card>
        </View>
      ))}
      {/* <Button
        onPress={() => {
          setOpenModal(true);
        }}
        style={{
          borderRadius: 12,
          backgroundColor: "#033A0C",
          marginBottom: 10,
        }}
        color="#fff"
      >
        Adicionar Nova Educação
      </Button> */}
      <TouchableOpacity
        onPress={() => {
          setSave(true);
          submitEducation();
        }}
        style={{
          alignSelf: "stretch",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#033A0C",
          paddingVertical: 10,
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        <LatoText bold style={{ color: "#fff" }}>
          Salvar
        </LatoText>
      </TouchableOpacity>
    </>
  );
};
export default EducationProfile;
