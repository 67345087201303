import LatoText from "../../components/LatoText";
import { StyleSheet, View } from "react-native";
import { Fragment } from "react";
import useGeneralizeColor from '../../hooks/generalizeColor';

const Personal = ({ userHistory }) => {
  const { rankingColors } = useGeneralizeColor();
  const user = userHistory.user;
  return (
    <>
    <br></br>
      <LatoText style={{paddingTop: 15}}>{user.firstName + " " + user.lastName}</LatoText><br></br>
      {user.education && user.education.length !== 0 && (
        <LatoText
          style={{
            fontSize: 12,
            textAlign: "center",
            color: "#888",
          }}
        >
          {user.education && user.education.length !== 0
            ? user.education[user.education.length - 1]["primaryFieldOfStudy"] +
              (user.education[user.education.length - 1]["fieldOfStudy2"]
                ? `, ${
                    user.education[user.education.length - 1]["fieldOfStudy2"]
                  }`
                : "") +
              " @ " +
              user.education[user.education.length - 1]["name"]
            : "..."}
        </LatoText>
      )}
      <div style={{ ...styles.pointsDisplay, backgroundColor: rankingColors?.personalPointsBox }}>
          <LatoText style={{fontSize: 13, display: "block", flex: 1, borderRight: "2px solid white"}}><span style={{fontWeight: "bold"}}><svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeWidth="2"
        d="M11.075 3.256c.34-.828 1.51-.828 1.85 0L14.65 7.46a1 1 0 00.85.617l4.531.341c.892.067 1.254 1.181.572 1.76l-3.465 2.939a1 1 0 00-.325 1l1.076 4.413c.212.87-.736 1.558-1.497 1.088l-3.865-2.387a1 1 0 00-1.051 0l-3.866 2.387c-.761.47-1.709-.219-1.497-1.088l1.076-4.414a1 1 0 00-.325-1l-3.465-2.938c-.682-.579-.32-1.693.572-1.76l4.53-.34a1 1 0 00.85-.618l1.725-4.204z"
      ></path>
    </svg><br></br>
    {userHistory.balance}</span><br></br> PONTOS</LatoText>


          <LatoText style={{fontSize: 13, display: "block", flex: 1}}><span style={{fontWeight: "bold"}}><svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeWidth="1.5"
        d="M22 12a10 10 0 11-20.001 0A10 10 0 0122 12h0z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="1.5"
        d="M16 12c0 1.313-.104 2.614-.305 3.827-.2 1.213-.495 2.315-.867 3.244-.371.929-.812 1.665-1.297 2.168-.486.502-1.006.761-1.531.761-.525 0-1.045-.259-1.53-.761-.486-.503-.927-1.24-1.298-2.168-.372-.929-.667-2.03-.868-3.244A23.614 23.614 0 018 12c0-1.313.103-2.614.304-3.827s.496-2.315.868-3.244c.371-.929.812-1.665 1.297-2.168C10.955 2.26 11.475 2 12 2c.525 0 1.045.259 1.53.761.486.503.927 1.24 1.298 2.168.372.929.667 2.03.867 3.244C15.896 9.386 16 10.687 16 12h0z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M2 12h20"
      ></path>
    </svg><br></br>
    #{userHistory.ranking}</span><br></br>RANKING</LatoText>
      </div>

          <LatoText style={{fontSize: 15, color: "#888"}}>Seus pontos:</LatoText>
        <View style={styles.list}>
          {userHistory.history.map((activity, index) => (
            <Fragment key={index}>
              <LatoText style={[styles.number]}>{index + 1}</LatoText>
              <div style={{ textAlign: "left", maxWidth: "40vw" }}>
                <LatoText bold style={styles.name}>
                  {activity.activity.name}
                </LatoText>
              </div>
              <LatoText style={styles.balance}>{activity.activity.points} pontos</LatoText>
            </Fragment>
          ))}
        </View>
    </>
  );
};

const styles = StyleSheet.create({
  pointsDisplay: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "65%",
    borderRadius: 15,
    justifyContent:"space-around",
    paddingTop:20,
    paddingBottom: 20,
    color: "white",
    fontWeight: "normal",
    marginTop: 15,
    minWidth: 150
  },
  name: {
    fontSize: 14,
    transform: "translate(0, -24%)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    overflow: "hidden",
    width: "100%",
    paddingLeft: 5,
    paddingRight: 5,
  },
  list: {
    display: "grid",
    width: "100%",
    gridAutoFill: true,
    gridTemplateColumns: "1fr 3fr 1fr",
  },
  balance: {
    fontSize: 10,
    color: "#AAAAAA",
    display: "inline-block",
    margin: "auto",
    whiteSpace: "nowrap",
    paddingRight: 20
  },
  listitem: {
    display: "flex",
    minWidth: "40px",
    justifyContent: "space-between",
    fontSize: 12,
    marginBottom: 5,
  },
  number: {
    borderRadius: "50%",
    borderWidth: 1,
    width: 25,
    height: 25,
    fontSize: 15,
    borderColor: "#AAAAAA",
    color: "#AAAAAA",
    margin: "auto",
    paddingTop: 2,
  },
});

export default Personal;
