import IUser from "../interfaces/IUser";
import api from "../util/axios";
import AsyncStorage from '@react-native-async-storage/async-storage';

export const generateOTP = async (email: string) => {
  return api.post("/webapp/user/otp", {email});
}

export const updateUserPassword = async (email: string, password: string, otp: string) => {
  return api.post("/webapp/user", {email, password, otp});
}

type AuthHandlers = {
  onLogin?: (user: IUser | null) => void;
  onLogout?: () => void;
};

class AuthService {
  user: IUser | null = null;

  handlers: AuthHandlers = {};

  static LOCAL_STORAGE_KEY = '@gobrasa:user';

  async init(handlers?: AuthHandlers) {
    const getFromStorage =  await AsyncStorage.getItem(AuthService.LOCAL_STORAGE_KEY);
    this.user = JSON.parse(getFromStorage|| 'null');
    if (handlers) {
      this.handlers = handlers;
    }
    if (this.user) {
      this.addInterceptors();
    }
  }

  private async saveUser() {
    return AsyncStorage.setItem(
      AuthService.LOCAL_STORAGE_KEY,
      JSON.stringify(this.user)
    );
  }

  private addInterceptors() {
    api.interceptors.request.use(
      config => {
        if (!config.headers) {
          // eslint-disable-next-line
          config.headers = {};
        }
        if(this.user && this.user.auth.token){
          // eslint-disable-next-line no-param-reassign
          config.headers['x-access-token'] = `${this.user.auth.token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );
    api.interceptors.response.use(
      response => response,
      error => {
        if (this.user && error?.response?.status === 401) {
          this.signOut();
        }
        return Promise.reject(error);
      }
    );
  }

  async signIn(email: string, password: string) {
    const response = await api.post('/auth2/user/login', { email, password });
    this.user = {
      ...response.data.user,
      auth: {
        token: response.data.token,
      },
    };
    this.addInterceptors();
    await this.saveUser();
    if (this.handlers.onLogin) {
      this.handlers.onLogin(this.user);
    }
    return response.data;
  }

  async signOut() {
    this.user = null;
    await AsyncStorage.removeItem(AuthService.LOCAL_STORAGE_KEY);
    if (this.handlers.onLogout) {
      this.handlers.onLogout();
    }
  }
}

const authService = new AuthService();

export default authService;
