import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import IUser from "../interfaces/IUser";
import authService from '../services/authServices';

interface AuthContextType {
  user: IUser | null;
  loading: boolean;
  initialLoading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  loading: false,
  initialLoading: false,
  signIn: async () => {},
  signOut: () => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    async function initAuth(){
      await authService.init({
        onLogout: () => {
          setUser(null);
          // enqueueSnackbar('You have been logged out', { variant: 'info' });
        },
      });
      setUser(authService.user);
      setInitialLoading(false);
    }
    initAuth();
  }, []);

  const signIn = async (newUser: string, password: string) => {
    setLoading(true);
    try {
      await authService.signIn(newUser, password);
      setUser(authService.user);
      return;
    } catch (error) {
      console.log(error);
      return error;
      // enqueueSnackbar(String(error), { variant: 'error' });
    }
    setLoading(false);
  };

  const signOut = () => {
    setLoading(true);
    authService.signOut();
    setLoading(false);
  };

  const value = useMemo(
    () => ({ user, loading, initialLoading, signIn, signOut }),
    [user, loading, initialLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
