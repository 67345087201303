import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { View } from 'react-native';
import {IconButton} from 'react-native-paper';
import { useAuth } from '../hooks/auth';

import Profile from '../pages/profile/Profile';
import ProfileEdit from '../pages/profile/ProfileEdit';
import colors from '../util/colors';

const Stack = createNativeStackNavigator();

export default function ProfileRouter() {
  const auth = useAuth();
  return (
    <Stack.Navigator
    screenOptions={{
      headerShown: false,
      headerStyle: {
        backgroundColor: colors.darkerBlue,
      },
      headerShadowVisible: false,
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      },
    }}>
      <Stack.Screen
        name="ProfilePage"
        component={Profile}
        options={({navigation, route})=>
        ({
        title: "Meu Perfil",
        headerRight: () => {
          return (
          <View style={{flexDirection: 'row'}}>
            <IconButton
              onPress={() => navigation.navigate('ProfileEdit', {info: route.params?.info ?? {}})}
              icon="pencil"
              color={"#ffffff"}
            />
            <IconButton
              onPress={() => auth.signOut()}
              icon="logout"
              color={"#ffffff"}
            />
          </View>
        )},})}
      />
      <Stack.Screen name="ProfileEdit" component={ProfileEdit} />
    </Stack.Navigator>
  );
}