import { useEffect, useState } from "react";
import { useConference } from "./conference";
import { useAuth } from "./auth";
import api from "../util/axios";

const useImage = (fileName, confSlug = "") => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const { user } = useAuth();
  const { currentSelectedConference } = useConference();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        let response;
        if (fileName.includes("https://brasa-api.s3.amazonaws.com/")) {
          fileName = fileName.split("https://brasa-api.s3.amazonaws.com/")[1];
          response = await api.get(fileName, {
            responseType: "arraybuffer",
            headers: {
              "Cache-Control": "max-age=86400", // set cache control header
            },
          });
        } else {
          response = await api.get(
            `/webapp/assets/${
              currentSelectedConference ? currentSelectedConference : confSlug
            }/${fileName}`,
            {
              responseType: "arraybuffer",
              headers: {
                "Cache-Control": "max-age=86400", // set cache control header
                "X-Access-Token": user.auth.token, // Get auth token
                PINTO_IMENSO: 123456789,
              },
            }
          );
        }
        const buffer = Buffer.from(response.data, "binary").toString("base64");
        setImage(`data:${response.headers["content-type"]};base64,${buffer}`);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [fileName, currentSelectedConference]);

  return {
    loading,
    error,
    image,
  };
};

export default useImage;
