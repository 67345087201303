//@ts-nocheck
import React from "react";
import {View} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';


const ProfileEditPicker = ({value = "", list, onChange, label, zIndex}) => {

    const [type, setType] = React.useState(value);
    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState(list);

    return <View style={{
        marginVertical: 5,
        zIndex: zIndex || 0
    }}>
        <DropDownPicker
            open={open}
            value={type}
            items={items}
            setOpen={setOpen}
            setValue={setType}
            setItems={setItems}
            style={{backgroundColor: 'none', borderColor: 'black', borderWidth: 1}}
            placeholder={label}
            onChangeValue={onChange}
            dropDownDirection="TOP"
        />
    </View>;
};

const ProfileEditPickerMultiple = ({value, list, onChange, label, zIndex}) => {

    const [type, setType] = React.useState(value.map((item) => item.id));
    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState(list);


    return <View style={{
        marginVertical: 5,
        zIndex: zIndex || 0
    }}>
        <DropDownPicker
            open={open}
            value={type}
            items={items}
            setOpen={setOpen}
            setValue={setType}
            setItems={setItems}
            style={{backgroundColor: 'none', borderColor: 'black', borderWidth: 1}}
            placeholder={label}
            onChangeValue={onChange}
            dropDownDirection="TOP"
            multiple={true}
            min={1}
            mode={"BADGE"}
            showBadgeDot={false}
        />
    </View>;
};

export {ProfileEditPicker, ProfileEditPickerMultiple}