import React, { useCallback, useState } from "react";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  ImageBackground,
} from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useConference } from "../../hooks/conference";
import IInitiative from "../../interfaces/IInitiative";
import {
  getParticipantInitiatives,
  getAllInitiatives,
  sendQuestion,
} from "../../services/initiativesService";
import DropDownPicker, { ItemType } from "react-native-dropdown-picker";
import summitBg from "../../assets/summitBg.png";
import { StatusBar } from "expo-status-bar";
import LatoText from "../../components/LatoText";
import { MaterialIcons } from "@expo/vector-icons";
import colors from "../../util/colors";
import HeaderRight from "../../components/HeaderRight";
import SanduicheMenu from "../sanduicheMenu/SanduicheMenu";
import { useNavigation } from "@react-navigation/native";
import useImage from "../../hooks/image.js";
type InitiativeWithRating = {
  rating?: number;
  initiative: IInitiative;
};

const QuestionPage = () => {
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const { currentSelectedConference } = useConference();
  const [initiatives, setInitiatives] = React.useState<ItemType<IInitiative>[]>(
    []
  );
  const [invalid, setInvalid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const navigation = useNavigation();
  const { loading: bgL, error: bgE, image: bg } = useImage("bg.png");
  useFocusEffect(
    useCallback(() => {
      async function fetch() {
        try {
          const response = await getAllInitiatives(currentSelectedConference);
          console.log("Response data:", response.data); // Log the response structure
          setInitiatives(
            response.data.initiatives
              .filter((initiative: IInitiative) => initiative.allowQuestions)
              .map((initiative: IInitiative) => ({
                label: initiative.name || "Unnamed Initiative",
                value: initiative.name || "Unnamed Initiative",
              }))
          );
        } catch (error) {
          console.log(error);
        }
      }
      if (currentSelectedConference) {
        fetch();
      }
    }, [])
  );

  const submitQuestion = async () => {
    if (text.length <= 10) {
      setInvalid(true);
    }
    if (text.length > 10 && value != null) {
      setInvalid(false);
      try {
        setLoading(true);
        // Send the question to the selected initiative regardless of registration
        await sendQuestion(currentSelectedConference, value, text);
        setText("");
        setValue(null);
      } catch (error) {
        setInvalid(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ImageBackground source={bg} style={{ flex: 1, padding: 0 }}>
      <LatoText
        style={{
          fontSize: 27,
          fontWeight: "bold",
          color: "#fff",
          padding: 20,
          alignSelf: "flex-start", // Align title to the left
          marginTop: 90, // Add margin to push the title down
        }}
      >
        Faça uma Pergunta
      </LatoText>
      <HeaderRight onPress={() => setMenuVisible(true)} />
      <View
        style={{
          backgroundColor: "#fff", // Background color for visibility
          width: "100%", // Width of the container
          flex: 1, // Take remaining space
          elevation: 2, // For shadow on Android
          shadowColor: "#000", // Shadow for iOS
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.2,
          shadowRadius: 1,
          justifyContent: "flex-start", // Align items at the top
          alignItems: "center", // Center content horizontally
          padding: 25, // Padding inside the container
          paddingTop: 0,
          margin: 0,
        }}
      >
        <StatusBar />
        {!menuVisible && (
          <>
            <View
              style={{
                flexDirection: "row",
                marginBottom: 32,
                alignItems: "center",
              }}
            ></View>
            <DropDownPicker
              style={{
                marginBottom: 25,
                borderColor: "#033A0C",
                borderWidth: 2,
                width: "100%",
              }}
              open={open}
              value={value}
              items={initiatives}
              setOpen={setOpen}
              setValue={setValue}
              setItems={setInitiatives}
              placeholder="Escolha um painel"
              translation={{ NOTHING_TO_SHOW: "Seleção vazia" }}
            />
            {value && (
              <>
                <TextInput
                  activeUnderlineColor={"#033A0C"}
                  mode="flat"
                  label={"Digite sua pergunta"}
                  error={invalid}
                  value={text}
                  multiline
                  onChangeText={(newText) => setText(newText)}
                  style={{
                    margin: 4,
                    flex: 1,
                    backgroundColor: "white",
                    borderWidth: 2,
                    borderColor: "033A0C",
                    borderRadius: 5,
                    padding: 10,
                    width: "100%",
                  }}
                />
                <Button
                  mode="contained"
                  disabled={loading || text.length <= 10}
                  style={{
                    backgroundColor: "#033A0C",
                    alignSelf: "stretch",
                    marginTop: 16,
                    borderRadius: 8,
                  }}
                  labelStyle={{ color: "white" }} // Use labelStyle to set the text color
                  onPress={submitQuestion}
                >
                  {loading ? "Enviando..." : "Enviar Pergunta"}
                </Button>
                {invalid && (
                  <View
                    style={{
                      alignSelf: "stretch",
                      marginTop: 16,
                      backgroundColor: "darkred",
                    }}
                  >
                    <LatoText style={{ color: "white" }}>
                      Erro ao enviar a pergunta, tente mais tarde
                    </LatoText>
                  </View>
                )}
              </>
            )}
          </>
        )}
      </View>
      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen: never) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </ImageBackground>
  );
};

export default QuestionPage;
