import React from 'react';
import WebApp from './src';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

export default function App() {
  return (
      <WebApp />
  );
}

serviceWorkerRegistration.register();