import { useEffect, useState } from 'react'
import { useConference } from './conference'
import eventColors from '../assets/defaultConfig.json';
import api from '../util/axios';

const useGeneralizeColor = (confSlug = "") => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const { currentSelectedConference } = useConference();
    const [conferenceColors, setConferenceColors] = useState();
    const [rankingColors, setRankingColors] = useState({});
    const [companies, setCompanies] = useState({});
    const [menuColors, setmenuColors] = useState({});

    useEffect(() => {
        const fetchGeneralize = async () => {
            try {
                const response = await api.get(`/webapp/assets/${currentSelectedConference ? currentSelectedConference : confSlug}/config.json`)
                setConferenceColors(response.data.schedule || {});
                setRankingColors(response.data.ranking || {});
                setCompanies(response.data.companies || {});
                setmenuColors(response.data.menu || {});
            } catch (err) {
                setConferenceColors(eventColors.schedule)
                setRankingColors(eventColors.ranking)
                setCompanies(eventColors.companies || {})
                setmenuColors(eventColors.menu || {})
                setError(err)
            } finally {
                setLoading(false)
            }
        }
        fetchGeneralize()
    }, [currentSelectedConference])

    return { conferenceColors, rankingColors, loading, error, companies, menuColors };
}

export default useGeneralizeColor
