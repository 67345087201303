import React from 'react';
import { StyleSheet, View } from 'react-native';

const ArcoBrasa = ({children, rootStyles, innerStyles}) => {
  return (
    <View style={[styles.border, styles.firstArch, rootStyles]} pointerEvents='none'>
      <View style={[styles.border, styles.secondArch]}> 
        <View style={[styles.border, styles.thirdArch, innerStyles]}>
          {children}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  border: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderWidth: 2,
    paddingTop: 8,
    paddingHorizontal: 6,
    paddingBottom: 0,
  },
  firstArch: {
    flex: 1,
    borderWidth: 3,
    borderColor: "#1589cb",
  },
  secondArch: {
    flex: 1,
    marginTop: -25,
    borderWidth: 4,
    borderColor: "#f5dc05",
  },
  thirdArch: {
    flex: 1,
    marginTop: -25,
    borderWidth: 6,
    borderColor: "#7ab341",
    paddingBottom: 30,
  }

})

export default ArcoBrasa;