import React, { useMemo, useCallback, useState } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  ImageBackground,
  Dimensions,
} from "react-native";
import { IconButton, Snackbar } from "react-native-paper";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import api from "../../util/axios";
import * as Linking from "expo-linking";
import LatoText from "../../components/LatoText";
import brasaBg from "../../assets/Group3.png";
import {
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import EducationInfoItem from "../../components/EducationInfoItem";
import Svg, { Path } from "react-native-svg";
import { useAuth } from "../../hooks/auth";
import QRCode from "react-native-qrcode-svg";
import { useConference } from "../../hooks/conference";
import HeaderRight from "../../components/HeaderRight";
import SanduicheMenu from "../sanduicheMenu/SanduicheMenu";
import useImage from "../../hooks/image.js";

const Profile = () => {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showEducation, setShowEducation] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [error, setError] = useState([false, null]);
  const navigation = useNavigation();
  const route = useRoute();
  const auth = useAuth();
  const [menuVisible, setMenuVisible] = useState(false);
  const { currentSelectedConference } = useConference();
  const {
    loading: bgL,
    error: bgE,
    image: bg,
  } = useImage("bg.png", "summitAme2024");

  const { height } = Dimensions.get("screen");
  const height_logo = height * 0.18;
  const { participantId } = useConference();

  useFocusEffect(
    useCallback(() => {
      async function fetchProfile() {
        try {
          setLoading(true);
          const { data } = await api.get("/webapp/profile");
          setInfo(data.user);
          navigation.setParams({
            info: data.user,
          });
          setLoading(false);
        } catch (err) {
          setError([true, err.message]);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }

      fetchProfile();
    }, [])
  );

  const education = useMemo(() => {
    if (!info || !info.education || info.education.length === 0) {
      return [];
    }
    return info.education.map((item) => ({
      label: item.name,
      value: `${item.primaryFieldOfStudy}${
        item.fieldOfStudy2 ? `, ${item.fieldOfStudy2}` : ""
      } - ${item.type}`,
    }));
  }, [info]);

  return (
    <ImageBackground source={bg} style={{ flex: 1, padding: 0 }}>
      <LatoText
        style={{
          fontSize: 27,
          fontWeight: "bold",
          color: "#fff",
          padding: 20,
          alignSelf: "center", // Align title to the center
          marginTop: 90, // Add margin to push the title down
        }}
      >
        Perfil
      </LatoText>

      {currentSelectedConference ? (
        <HeaderRight onPress={() => setMenuVisible(true)} />
      ) : null}

      <View
        style={{
          backgroundColor: "#fff", // Background color for visibility
          width: "100%", // Width of the container
          flex: 1, // Take remaining space
          elevation: 2, // For shadow on Android
          shadowColor: "#000", // Shadow for iOS
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.2,
          shadowRadius: 1,
          justifyContent: "flex-start", // Align items at the top
          alignItems: "center", // Center content horizontally
          padding: 25, // Padding inside the container
          paddingTop: 0,
          margin: 0,
        }}
      >
        {loading ? (
          <LatoText style={styles.loadingText}>Loading...</LatoText>
        ) : (
          info && (
            <View style={styles.profileContainer}>
              <View style={styles.profilePictureContainer}>
                <ImageBackground
                  source={brasaBg}
                  style={styles.profilePicturePlaceholder}
                  resizeMode="contain"
                />
              </View>

              <View style={styles.content}>
                <View style={styles.nameEditContain}>
                  <LatoText style={styles.nameText}>
                    {info.firstName} {info.lastName}
                  </LatoText>

                  {/* Pencil Icon for Edit Profile */}
                  <IconButton
                    onPress={() =>
                      navigation.navigate("ProfileEdit", {
                        info: route.params?.info ?? {},
                      })
                    }
                    icon="pencil"
                    color={"#033A0C"}
                    size={25}
                    style={styles.editIcon}
                  />
                </View>

                {info.education && info.education.length > 0 && (
                  <View style={styles.universityContainer}>
                    <FontAwesome5 name="book-open" size={18} color="#033A0C" />
                    <LatoText style={styles.educationText}>
                      {`${
                        info.education[info.education.length - 1][
                          "primaryFieldOfStudy"
                        ]
                      } ${
                        info.education[info.education.length - 1][
                          "fieldOfStudy2"
                        ] || ""
                      }`}
                    </LatoText>
                  </View>
                )}

                {info.education && info.education.length > 0 && (
                  <View style={styles.universityContainer}>
                    <MaterialIcons name="school" size={18} color="#033A0C" />
                    <LatoText style={styles.universityText}>
                      {info.education[info.education.length - 1]["name"]}
                    </LatoText>
                  </View>
                )}

                {info.birthPlace && (
                  <View style={styles.cityContainer}>
                    <MaterialCommunityIcons
                      name="map-marker"
                      size={20}
                      color="#033A0C"
                    />
                    <LatoText style={styles.cityText}>
                      {info.birthPlace}
                    </LatoText>
                  </View>
                )}

                <View style={styles.linksContainer}>
                  {info.linkedin && (
                    <IconButton
                      onPress={() => Linking.openURL(info.linkedin)}
                      icon="linkedin"
                      color={"#033A0C"}
                      size={34}
                      style={styles.iconButtonL}
                    />
                  )}
                  {info.email && (
                    <IconButton
                      onPress={() => Linking.openURL(`mailto:${info.email}`)}
                      icon={() => (
                        <FontAwesome5
                          name="envelope"
                          size={32}
                          color="#033A0C"
                          solid
                        />
                      )}
                      style={styles.iconButton}
                    />
                  )}
                  {info.phoneNumberCountryCode && info.phoneNumber && (
                    <IconButton
                      onPress={() =>
                        Linking.openURL(
                          `https://wa.me/${info.phoneNumberCountryCode}${info.phoneNumber}`
                        )
                      }
                      icon={() => (
                        <FontAwesome5
                          name="whatsapp"
                          size={32}
                          color="#033A0C"
                        />
                      )}
                      // style={styles.iconButton}
                    />
                  )}

                  {info.resumeUrl && (
                    <IconButton
                      onPress={() =>
                        Platform.OS === "web"
                          ? window.open(
                              `${api.getUri()}/webapp/user/getCV/${
                                auth.user.id
                              }?auth=${auth.user.auth.token}`,
                              "_blank"
                            )
                          : {}
                      }
                      icon="file-document"
                      color={"#033A0C"}
                      size={32}
                      solid
                    />
                  )}
                </View>
              </View>

              {/* <View style={styles.aboutContainer}>
                <LatoText style={styles.aboutTitle}>About You</LatoText>
                <LatoText style={styles.aboutText}>
                  {info.aboutYou || "No description available."}
                </LatoText>
              </View> */}

              {participantId && (
                <View style={styles.qrCodeContainer}>
                  <View style={styles.qrCodeWrapper}>
                    <View style={styles.qrCodeInnerWrapper}>
                      <QRCode
                        value={`${window.location.hostname}/connect?participantId=${participantId}`}
                        size={110}
                        backgroundColor="white"
                        color="#033A0C"
                      />
                    </View>
                  </View>
                </View>
              )}

              <FlatList
                style={{ marginTop: 16 }}
                data={showEducation ? education : []}
                keyExtractor={(item, index) => `${index}-${item.label}`}
                renderItem={({ item }) => (
                  <EducationInfoItem label={item.label} value={item.value} />
                )}
                contentContainerStyle={{ paddingHorizontal: 30 }}
              />
            </View>
          )
        )}
      </View>

      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  titleText: {
    fontSize: 27,
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: 25,
    alignSelf: "center",
    marginTop: 30,
    textAlign: "center",
  },
  editIcon: {
    margin: 0,
    padding: 0,
  },
  loadingText: {
    fontSize: 20,
    color: "#033A0C",
    textAlign: "center",
    marginTop: 20,
  },
  profileContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    flex: 1,
    shadowColor: "white",
    shadowRadius: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 10,
    paddingTop: 0,
    position: "relative", // Keep it relative to position child elements absolutely
    resizeMode: "contain",
  },
  profilePictureContainer: {
    width: 100,
    height: 100,
    borderRadius: 60,
    borderWidth: 3,
    borderColor: "#fff",
    backgroundColor: "#033A0C",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 5,
    position: "absolute", // Use absolute positioning
    top: -65, // Adjust this value to raise the profile picture higher
    left: "17%", // Center it horizontally
    marginLeft: -50, // Adjust to account for half the width (120/2)
    resizeMode: "contain",
  },
  profilePicturePlaceholder: {
    width: "100%",
    height: "80%",
    borderRadius: 50,
    marginTop: 18,
    resizeMode: "contain",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 250,
    marginBottom: 50,
  },
  nameEditContain: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-start",
    // alignContent: "flex-start",
    marginBottom: 15,
  },
  nameText: {
    fontSize: 22,
    fontWeight: "1000",
    color: "#033A0C",
  },
  universityContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  educationText: {
    fontSize: 16,
    color: "#033A0C",
    fontWeight: "light",
    marginLeft: 5,
    textAlign: "left",
  },
  universityText: {
    fontSize: 16,
    color: "#033A0C",
    fontWeight: "light",
    marginLeft: 5,
    textAlign: "left",
  },
  cityContainer: {
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "light",
    marginTop: 4,
  },
  cityText: {
    fontSize: 16,
    color: "#033A0C",
    marginLeft: 5,
    textAlign: "left",
    fontWeight: "light",
  },
  linksContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 90,
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    marginLeft: 5,
  },
  iconButton: {
    marginRight: 17,
  },
  iconButtonL: {
    marginLeft: -10,
  },
  // aboutContainer: {
  //   marginTop: 30,
  //   width: "100%",
  // },
  // aboutTitle: {
  //   fontSize: 18,
  //   fontWeight: "800",
  //   marginBottom: 5,
  //   marginTop: 10,
  //   textAlign: "left",
  //   color: "#033A0C",
  // },
  // aboutText: {
  //   fontSize: 16,
  //   textAlign: "left",
  //   marginTop: 5,
  //   marginBottom: 10,
  //   color: "#033A0C",
  // },
  qrCodeContainer: {
    backgroundColor: "white", // Main container with white background
    padding: 10, // Padding around the inner grey container
    borderRadius: 15, // Rounded corners for the main container
    borderWidth: 1, // Optional: Border for main container
    borderColor: "white", // Border color for main container
    width: "100%", // Full width of the screen
    justifyContent: "center", // Center content vertically
    alignItems: "center", // Center content horizontally
    flexDirection: "row", // Ensure proper horizontal alignment
    marginTop: 15,
  },
  qrCodeWrapper: {
    justifyContent: "center", // Centers the inner grey container vertically
    alignItems: "center", // Centers the inner grey container horizontally
    borderRadius: 15, // Rounded corners for the grey container
    width: 150, // Set to match the QR code size
    height: 150, // Set to match the QR code size
    backgroundColor: "#f0f0f0", // Light grey background
    overflow: "hidden",
  },
  qrCodeInnerWrapper: {
    justifyContent: "center", // Centers the QR code vertically
    alignItems: "center", // Centers the QR code horizontally
    borderRadius: 15, // Rounded corners for the QR code container
    width: 130, // Slightly smaller than the grey container
    height: 130, // Slightly smaller than the grey container
    backgroundColor: "white", // White background for the QR code itself
  },
});

export default Profile;
