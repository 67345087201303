import React, { useState } from "react";
import ProfileFields from "../../../enums/ProfileFields";
import { Snackbar } from "react-native-paper";
import api from "../../../util/axios";
import colors from "../../../util/colors";
import LatoText from "../../../components/LatoText";
import { TouchableOpacity, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import CVModal from "../../../components/CVModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ConfirmationModal from "./ConfirmationModal";

const MainProfile = ({ setSave }) => {
  const route = useRoute();
  const [cvModal, setCvModal] = React.useState(false);
  const [form, setForm] = useState(route.params?.info ?? {});
  const [sOpen, setSOpen] = useState([false, "", ""]); // [visibility, message, snackbar color]
  const profileFields = ProfileFields(form, setForm).personal;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submitPersonal = () => {
    api
      .put(`/webapp/user`, form)
      .then((res) => {
        setIsModalOpen(true);
      })
      .catch((err) => {
        setSOpen([true, err.message, "#df4759"]);
      })
      .finally(() => {
        setSave(false);
      });
  };

  return (
    <>
      <ConfirmationModal visible={isModalOpen} setVisible={setIsModalOpen} />
      <CVModal
        visible={cvModal}
        setVisible={setCvModal}
        form={form}
        setForm={setForm}
      />
      <TouchableOpacity
        onPress={() => {
          setCvModal(true);
        }}
        style={{
          alignSelf: "stretch",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderWidth: 1.5,
          borderColor: "#033A0C",
          paddingVertical: 4,
          borderRadius: 8,
          marginBottom: 5,
        }}
      >
        <MaterialCommunityIcons name="file-upload" size={24} color="#033A0C" />
        <LatoText black style={{ color: "#033A0C", marginLeft: 8 }}>
          Enviar CV
        </LatoText>
      </TouchableOpacity>

      {profileFields.map((item, index) => {
        if (item.hasOwnProperty("render")) {
          return <View key={index}>{item.render}</View>;
        }
      })}
      <TouchableOpacity
        onPress={() => {
          setSave(true);
          submitPersonal();
        }}
        style={{
          alignSelf: "stretch",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#033A0C",
          paddingVertical: 10,
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        <LatoText bold style={{ color: "#FFF" }}>
          Salvar
        </LatoText>
      </TouchableOpacity>
    </>
  );
};

export default MainProfile;
