import { View } from "react-native";
import LatoText from "../../components/LatoText";
import { StyleSheet } from "react-native";
import { Fragment } from "react";

const Global = ({ data }) => {
  return (
    <View
      style={[
        styles.list,
        {
          background:
            "repeating-linear-gradient(transparent 0 38.29px,#eee 38.29px 78.10px)",
        },
      ]}
    >
      {data.map((person, index) => (
        <Fragment key={index}>
          <LatoText style={[styles.number]}>{index + 1}</LatoText>
          <div style={{ textAlign: "left", maxWidth: "30vw" }}>
            <LatoText bold style={styles.name}>
              {person.name}
            </LatoText>
          </div>
          <LatoText light style={styles.balance}>
            {person.balance} pontos
          </LatoText>
          <div style={{ width: "100px", height: "37px" }}>
            {[0, 1, 2].includes(index) ? (
              <svg
                style={{ margin: "auto" }}
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                fill="none"
                viewBox="0 0 37 37"
              >
                <g filter="url(#filter0_d_2_765)">
                  <circle
                    cx="18.184"
                    cy="17"
                    r="15"
                    fill={["#FFD54B", "#E1E1E2", "#F6B191"][index]}
                  ></circle>
                  <path
                    fill="#fff"
                    d="M18.185 7.185l3.024 6.05 6.05.756-4.154 4.658 1.13 6.684-6.05-3.024-6.05 3.024 1.14-6.684L9.11 13.99l6.049-.756 3.025-6.05z"
                  ></path>
                </g>
                <defs>
                  <filter
                    id="filter0_d_2_765"
                    width="35.689"
                    height="35.689"
                    x="0.34"
                    y="0.578"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1.422"></feOffset>
                    <feGaussianBlur stdDeviation="1.422"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_2_765"
                    ></feBlend>
                    <feBlend
                      in="SourceGraphic"
                      in2="effect1_dropShadow_2_765"
                      result="shape"
                    ></feBlend>
                  </filter>
                </defs>
              </svg>
            ) : null}
          </div>
          {/* </div> */}
        </Fragment>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  name: {
    fontSize: 14,
    transform: "translate(0, -24%)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    overflow: "hidden",
    width: "100%",
    paddingLeft: 5,
    paddingRight: 5,
  },
  list: {
    display: "grid",
    gridAutoFill: true,
    gridTemplateColumns: "1fr 3fr 1fr 1fr",
    borderRadius: 30,
    height: "100%",
  },
  balance: {
    fontSize: 10,
    color: "#AAAAAA",
    display: "inline-block",
    margin: "auto",
    whiteSpace: "nowrap",
  },
  listitem: {
    display: "flex",
    minWidth: "40px",
    justifyContent: "space-between",
    fontSize: 12,
    marginBottom: 5,
  },
  number: {
    borderRadius: "50%",
    borderWidth: 1,
    width: 25,
    height: 25,
    fontSize: 15,
    borderColor: "#AAAAAA",
    color: "#AAAAAA",
    margin: "auto",
    paddingTop: 2,
  },
});

export default Global;
