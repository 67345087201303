import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { getConnections } from '../services/connectionsServices';
import { getAllInitiatives } from '../services/initiativesService';
import { getParticipantId } from '../services/participantServices';
import { useAuth } from './auth';
import api from '../util/axios';

const ConferenceContext = React.createContext({
  currentSelectedConference: null,
  currentSelectedConferenceId: null,
  selectConference: (conferenceSlug) => {},
  deselectConference: () => {},
  participantId: null,
  connections: [],
  conferences: [],
  allInitiatives: [],
  participantConferences: [],
  refreshConnections: (participantId) => {},
});

export function ConferenceProvider({ children }) {
  const [participantId, setCurrentParticipantId] = useState(null);
  const [currentSelectedConference, setCurrentSelectedConference] = useState(null);
  const [currentSelectedConferenceId, setCurrentSelectedConferenceId] = useState(null);
  const [connections, setConnections] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [allInitiatives, setAllInitiatives] = useState([]);
  const [participantConferences, setParticipantConferences] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
      Promise.all([api.get('/webapp/conferences'), api.get('/webapp/participant/conferences')]).then((response) => {
        setConferences(response[0].data);
        setParticipantConferences(response[1].data.participantConferences);
      }).catch(err => console.log(err));
      //selectConference('summit2022');
  }, [user]);

  const selectConference = async (conferenceSlug) => {
    // setLoading(true);
    try {
      const {participant} = (await getParticipantId(conferenceSlug)).data;
      setCurrentSelectedConference(conferenceSlug);
      let id;
      if (!conferences || conferences.length === 0)
        id = (await api.get('/webapp/participant/conferences')).data.participantConferences.find(x => x.conference.slug === conferenceSlug).conference.id;
      else
        id = conferences.find(x => x.slug == conferenceSlug).id;
      setCurrentSelectedConferenceId(id);
      if(participant) {
        setCurrentParticipantId(participant.id);
        refreshConnections(participant.id);
      }
      const {initiatives} = (await getAllInitiatives(conferenceSlug)).data;
      setAllInitiatives(initiatives);
    } catch (error) {
        console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  const deselectConference = () => {
    setCurrentParticipantId(null);
    setCurrentSelectedConference(null);
    setCurrentSelectedConferenceId(null);
    setConnections([]);
    setAllInitiatives([]);
  }

  const refreshConnections = async (participantId) => {
    if(participantId) {
      const {connections} = (await getConnections(participantId)).data;
      setConnections(connections);
    }
  }
  const value = useMemo(
    () => ({refreshConnections, participantId, currentSelectedConference, currentSelectedConferenceId, allInitiatives, selectConference, deselectConference, connections, conferences, participantConferences}),
    [refreshConnections, participantId, currentSelectedConference, currentSelectedConferenceId,allInitiatives, selectConference, deselectConference, connections, conferences, participantConferences]
  );

  return <ConferenceContext.Provider value={value}>{children}</ConferenceContext.Provider>;
}

export function useConference() {
  const context = React.useContext(ConferenceContext);

  if (!context) {
    throw new Error('useConference must be used within a ConferenceProvider');
  }

  return context;
}
