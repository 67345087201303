//@ts-nocheck
import React from "react";
import { View, Platform } from 'react-native';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'


const ProfileEditSearch = ({placeholder, items, onSelect, inputSearchString, zIndex}) => {
  return <View  style={{
      marginVertical: 5,
      zIndex: zIndex,
    }} >
        <ReactSearchAutocomplete
            items={items}
            onSelect={onSelect}
            showIcon={false}
            styling = { {
                height: "40px",
                border: "1px solid black",
                borderRadius: "4px",
                boxShadow: "none",
                clearIconMargin: "3px 8px 0 0",
            }}
            placeholder={placeholder}
            inputSearchString={inputSearchString}
            showNoResults = {false}

            />
    </View>;
};


export default ProfileEditSearch