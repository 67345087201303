import React, {useState, useEffect} from 'react';
import {Portal, Text, Button, Provider} from 'react-native-paper';
import {Modal, StyleSheet, View, TouchableOpacity, TouchableWithoutFeedback} from "react-native";
import ProfileEditInfo from './ProfileEditInfo';
import DropDownPicker from 'react-native-dropdown-picker';
import api from '../util/axios';
import CourseAutocomplete from './CourseAutocomplete';
import ProfileEditSearch from './ProfileEditSearch';
import ProfileUniSearch from "./ProfileUniSearch";

export default function ProfileEditUniModal({visible, setVisible, profileFields, handleSubmit}) {

    const hideModal = () => {
        setVisible(false)
    };

    return (
        <View>
            <Modal
                animationType="slide"
                transparent={true}
                visible={visible}
                onRequestClose={hideModal}
                style={{
                    position: "relative"
                }}
            >
                <TouchableOpacity
                    style={styles.centeredView}
                    onPress={hideModal}
                >
                    <TouchableWithoutFeedback>
                        <View style={styles.modalView}>
                            {profileFields.map((item, id) => {
                                if (item.hasOwnProperty('render')) {
                                    return <View style={{width: '100%', zIndex: 100-id}} key={id}>{item.render(0)}</View>;
                                }
                            })}
                            <View style={{flexDirection: 'row', justifyContent: 'space-evenly', margin: 4}}>
                                <Button onPress={hideModal} color="#1589cb">Cancelar</Button>
                                <Button onPress={handleSubmit} color="#1589cb">Adicionar</Button>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </TouchableOpacity>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    }
});
