import React from "react";
import {
  View,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
import { ProgressBar, IconButton } from "react-native-paper";
import CVModal from "../../components/CVModal";
import { useRoute, useNavigation } from "@react-navigation/native";

import ArcoBrasa from "../../components/ArcoBrasaLados";
import LatoText from "../../components/LatoText";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import MainProfile from "./ProfileEdit/MainProfile";
import EducationProfile from "./ProfileEdit/EducationProfile";
import useImage from "../../hooks/image.js";

export default function ProfileEdit() {
  const navigation = useNavigation();
  const {
    loading: bgL,
    error: bgE,
    image: bg,
  } = useImage("bg.png", "summitAme2024");
  const [save, setSave] = React.useState(false);
  const [showEducation, setShowEducation] = React.useState(false);

  return (
    <ImageBackground source={bg} style={{ flex: 1, padding: 0 }}>
      <View style={{ flex: 1, zIndex: 999 }}>
        {save ? <ProgressBar color="white" indeterminate /> : null}
        <View
          style={{
            height: 60,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onPress={() => {
              navigation.pop(1);
            }}
            icon={"chevron-left"}
            color={"#fff"}
            size={36}
          />
          <View style={{ flexDirection: "row" }}>
            <IconButton
              onPress={() => {
                setShowEducation(!showEducation);
              }}
              icon={showEducation ? "account" : "school"}
              color={"#fff"}
              size={30}
            />
          </View>
        </View>
        <LatoText
          style={{
            fontSize: 27,
            fontWeight: "bold",
            color: "#fff",
            padding: 20,
            alignSelf: "flex-start", // Align title to the left
            marginTop: 30, // Add margin to push the title down
          }}
        >
          Edite seu Perfil
        </LatoText>
        <ScrollView
          style={{
            marginTop: 8,
            paddingVertical: 30,
            paddingHorizontal: 60,
            backgroundColor: "#fff",
            flex: 1,
            alignSelf: "stretch",
          }}
        >
          {showEducation ? (
            <EducationProfile setSave={setSave} />
          ) : (
            <MainProfile setSave={setSave} />
          )}
        </ScrollView>
      </View>
    </ImageBackground>
  );
}
