import { useFocusEffect, useRoute } from "@react-navigation/native";
import * as MailComposer from "expo-mail-composer";
import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Platform,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  View,
  ScrollView,
  TextInput,
} from "react-native";
import { useConference } from "../../hooks/conference";
import Loading from "../../hooks/loading";
import * as Linking from "expo-linking";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import BackToMenu from "../../components/BackToMenu";
import LatoText from "../../components/LatoText";
import { useAuth } from "../../hooks/auth";
import useImage from "../../hooks/image.js";
import api from "../../util/axios";
import HeaderRight from "../../components/HeaderRight";
import SanduicheMenu from "../sanduicheMenu/SanduicheMenu";
import { NavigationProp, useNavigation } from "@react-navigation/native";

const ContactsBook2 = () => {
  const { conferences, currentSelectedConference, participantId } =
    useConference();
  const route = useRoute();
  const [selectedConnection, setSelectedConnection] = useState(
    route.params && (route.params as any)?.autoOpenContact
  );
  const { image: bg, loading: bgLoading } = useImage("bg.png");
  const { image: brasaStamp, loading: brasaStampLoading } =
    useImage("colorido.png");
  const { image: brasaWhiteS, loading: brasaWhiteSLoading } =
    useImage("branco.png");
  const [connections, setConnections] = useState(null);
  const auth = useAuth();
  const [menuVisible, setMenuVisible] = useState(false);
  const navigation = useNavigation();
  const [showDetails, setShowDetails] = useState(null); // State to control the selected contact details
  const [searchText, setSearchText] = useState(""); // New state for search input

  const refreshConnections = (participantId) => {
    if (!participantId) return;
    api
      .get(`/webapp/connections/${participantId}`)
      .then((res) => {
        const connections = res.data.connections;
        setConnections(connections);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useFocusEffect(
    useCallback(() => {
      refreshConnections(participantId);
      return () => {
        setSelectedConnection(null);
      };
    }, [])
  );

  useEffect(() => {
    refreshConnections(participantId);
  }, []);

  const filteredConnections = connections
    ? connections.filter((connection) =>
        `${connection.firstName} ${connection.lastName}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    : [];

  if (connections === null) {
    return <Loading />;
  }

  return (
    <View style={{ flex: 1 }}>
      {/* <BackToMenu /> */}
      <HeaderRight onPress={() => setMenuVisible(true)} />
      <ImageBackground
        source={bg}
        style={{ flex: 1, alignItems: "center", justifyContent: "flex-start" }}
      >
        <ScrollView
          contentContainerStyle={{ alignItems: "center", padding: 20 }}
        >
          <LatoText bold style={styles.title}>
            Contatos
          </LatoText>
          {/* Move Search Bar below the title */}
          <View style={styles.searchContainer}>
            <FontAwesome name="search" size={24} color="#888" />
            <TextInput
              style={styles.searchInput}
              placeholder="Search by name"
              placeholderTextColor="#888"
              value={searchText}
              onChangeText={setSearchText}
            />
          </View>

          {filteredConnections.length === 0 ? (
            <LatoText style={styles.noContacts}>No contacts found</LatoText>
          ) : (
            filteredConnections.map((connection, index) => (
              <TouchableOpacity
                key={index}
                style={styles.contactContainer}
                onPress={() =>
                  setShowDetails(showDetails === connection ? null : connection)
                } // Toggle details on click
              >
                <Image source={brasaStamp} style={styles.stampCol} />
                <View style={styles.contactInfo}>
                  <LatoText
                    bold
                    style={styles.h1}
                  >{`${connection.firstName.toUpperCase()} ${connection.lastName.toUpperCase()}`}</LatoText>
                  <LatoText style={styles.h2}>
                    {
                      connection.education[connection.education.length - 1][
                        "primaryFieldOfStudy"
                      ]
                    }
                  </LatoText>
                  <LatoText light style={styles.h3}>
                    {
                      connection.education[connection.education.length - 1][
                        "name"
                      ]
                    }
                  </LatoText>
                </View>
              </TouchableOpacity>
            ))
          )}
        </ScrollView>

        {showDetails && (
          <View style={styles.detailsContainer}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setShowDetails(null)}
            >
              <FontAwesome name="times" size={20} color="#FFFFFF" />
            </TouchableOpacity>
            <LatoText bold style={styles.detailsTitle}>
              {`${showDetails.firstName.toUpperCase()} ${showDetails.lastName.toUpperCase()}`}
            </LatoText>
            <View style={styles.detailsContent}>
              <Image source={brasaWhiteS} style={styles.stampB} />
              <View style={styles.detailsInfo}>
                <View style={styles.iconRow}>
                  <FontAwesome
                    name="book"
                    size={18}
                    style={styles.iconOutline2}
                  />
                  <LatoText light style={styles.detailsText}>
                    {
                      showDetails.education[showDetails.education.length - 1][
                        "primaryFieldOfStudy"
                      ]
                    }
                  </LatoText>
                </View>
                <View style={styles.iconRow}>
                  <FontAwesome
                    name="graduation-cap"
                    size={18}
                    style={styles.iconOutline}
                  />
                  <LatoText light style={styles.detailsText}>
                    {
                      showDetails.education[showDetails.education.length - 1][
                        "name"
                      ]
                    }
                  </LatoText>
                </View>
                <View style={styles.iconRow}>
                  <FontAwesome
                    name="map-marker"
                    size={18}
                    style={styles.iconOutline3}
                  />
                  <LatoText light style={styles.detailsText}>
                    {`${showDetails.birthPlace}`}
                  </LatoText>
                </View>
              </View>
            </View>
            <View style={styles.iconContainer}>
              {showDetails.phoneNumberCountryCode &&
                showDetails.phoneNumber && (
                  <TouchableOpacity
                    onPress={() =>
                      Platform.OS === "web" &&
                      window.open(
                        `https://wa.me/${showDetails.phoneNumberCountryCode}${showDetails.phoneNumber}`,
                        "_blank"
                      )
                    }
                  >
                    <FontAwesome name="whatsapp" size={25} color="#FFFFFF" />
                  </TouchableOpacity>
                )}
              {showDetails.linkedin && showDetails.linkedin.trim() !== "" && (
                <TouchableOpacity
                  onPress={() => Linking.openURL(showDetails.linkedin)}
                >
                  <FontAwesome name="linkedin" size={25} color="#FFFFFF" />
                </TouchableOpacity>
              )}
              {showDetails.resumeUrl && (
                <TouchableOpacity
                  onPress={() =>
                    window.open(
                      `${api.getUri()}/webapp/user/getCV/${
                        showDetails.id
                      }?auth=${auth.user.auth.token}`,
                      "_blank"
                    )
                  }
                >
                  <FontAwesome name="file-text-o" size={20} color="#FFFFFF" />
                </TouchableOpacity>
              )}
              {showDetails.email && (
                <TouchableOpacity
                  onPress={() =>
                    MailComposer.composeAsync({
                      recipients: [showDetails.email],
                      subject: `We connected at ${
                        conferences.find(
                          (i) => currentSelectedConference === i.slug
                        ).name
                      }!`,
                    })
                  }
                >
                  <FontAwesome name="envelope" size={20} color="#FFFFFF" />
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
      </ImageBackground>
      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </View>
  );
};

export default ContactsBook2;

const styles = StyleSheet.create({
  title: {
    fontSize: 28,
    marginTop: 8,
    marginBottom: 20,
    color: "#FFFFFF",
  },
  noContacts: {
    fontSize: 20,
    color: "#FFFFFF",
  },
  contactContainer: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    width: "100%", // Ensure full width
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  contactInfo: {
    marginLeft: 10, // Space between image and text
    flexDirection: "column",
    justifyContent: "center",
    flexShrink: 1,
  },
  stampCol: {
    width: 60,
    height: 60,
    resizeMode: "contain", // Ensure images are not cut off
  },
  stampB: {
    width: 80,
    height: 80,
    resizeMode: "contain", // Ensure images are not cut off
  },
  h1: {
    fontSize: 18,
    textAlign: "left",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: 4,
    flexShrink: 1, // Garante que o título se ajuste dentro do container
    maxWidth: "100%", // Limita o comprimento máximo do texto ao tamanho do container
    flexWrap: "wrap", // Quebra o texto em várias linhas se necessário
  },
  h2: {
    fontSize: 16,
    textAlign: "left",
    fontWeight: "300",
    paddingBottom: 1,
    paddingLeft: 4,
    flexShrink: 1, // Garante que o texto se ajuste dentro do container
    maxWidth: "100%", // Limita o comprimento máximo do texto ao tamanho do container
    flexWrap: "wrap", // Quebra o texto em várias linhas se necessário
  },
  h3: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "normal",
    paddingBottom: 1,
    paddingLeft: 4,
    flexShrink: 1, // Garante que o texto se ajuste dentro do container
    maxWidth: "100%", // Limita o comprimento máximo do texto ao tamanho do container
    flexWrap: "wrap", // Quebra o texto em várias linhas se necessário
  },
  detailsContainer: {
    backgroundColor: "#002800",
    padding: 10,
    position: "absolute",
    paddingLeft: 20,
    bottom: 0,
    left: 0,
    right: 0,
  },
  detailsTitle: {
    fontSize: 20,
    textAlign: "center",
    color: "#FFF",
  },
  detailsContent: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  detailsInfo: {
    marginLeft: 30,
  },
  detailsText: {
    color: "#FFF",
    fontSize: 14,
    paddingLeft: 5,
  },
  detailsImage: {
    width: 80,
    height: 80,
  },
  iconRow: {
    flexDirection: "row",
    marginVertical: 5,
    alignItems: "flex-end", // Align items at the bottom
  },
  iconOutline: {
    marginRight: 6,
    color: "#FFF",
  },
  iconOutline2: {
    marginRight: 9,
    marginLeft: 4,
    color: "#FFF",
  },
  iconOutline3: {
    marginRight: 13,
    marginLeft: 5,
    color: "#FFF",
  },
  iconContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: 20,
    alignItems: "flex-end", // Align items at the bottom
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#FFFFFF",
    marginTop: 20, // Adjusted margin to position it below the title
    marginBottom: 20,
    borderRadius: 8,
    width: "100%",
  },
  searchInput: {
    flex: 1,
    paddingHorizontal: 8,
    color: "#000",
  },
  closeButton: {
    alignSelf: "flex-end",
    padding: 10,
  },
});
