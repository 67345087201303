//@ts-nocheck
import React from "react";
import {View} from 'react-native';
import {TextInput} from "react-native-paper";


const ProfileEditInfo = ({head, text, onChange, error = false, bypassError = false, disabled = false, zIndex = 1}) => {
    return <View style={{
        marginVertical: 5,
        width: '100%',
        zIndex: zIndex
    }}>
        <TextInput
            activeOutlineColor="#0072b1"
            mode='outlined'
            dense={true}
            label={head}
            error={text && text !== '' && error && !bypassError}
            value={text}
            onChangeText={onChange}
            disabled={disabled}
        />
    </View>;
};


export default ProfileEditInfo