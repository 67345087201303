import React from 'react';
import { View, StyleSheet, ImageBackground, TouchableOpacity, Image, Linking, ScrollView } from 'react-native';
import LatoText from '../../components/LatoText';
import useImage from "../../hooks/image";
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import useGeneralizeColor from '../../hooks/generalizeColor';
import Loading from '../../hooks/loading';
import useParceiros from '../../hooks/parceiros';

const BackButton = ({ onPress }) => (
  <TouchableOpacity style={styles.backButton} onPress={onPress}>
    <Ionicons name="arrow-back" size={24} color="black" />
  </TouchableOpacity>
);

const Company = () => {
  const { loading: bgL, error: bgE, image: bg } = useImage('companyBg.png');
  const { companies, loading: companiesLoading, error: companiesError } = useGeneralizeColor();
  const { parceirosInfos, loading: parceirosInfosLoading, errors: parceirosInfosErrors } = useParceiros();
  const navigation = useNavigation();
  const route = useRoute();
  const { parceiroChave } = route.params;

  const handleBackPress = () => {
    navigation.goBack();
  };

  if (companiesLoading || bgL || parceirosInfosLoading) {
    return <Loading />;
  }

  if (companiesError || bgE || parceirosInfosErrors.length) {
    return <LatoText>Error loading data</LatoText>;
  }

  const parceiroData = companies ? companies[parceiroChave] : null;
  const parceiroImage = parceirosInfos.find(img => img.chave === parceiroChave)?.logo;

  if (!parceiroData) {
    return <LatoText>Error loading data</LatoText>;
  }

  return (
    <View style={styles.containerBack}>
      <BackButton onPress={handleBackPress} />
      <ImageBackground source={bg} style={styles.imageBg} imageStyle={{ resizeMode: 'cover' }}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.image__containerWrapper}>
              <View style={styles.image__container}>
                {parceiroImage ? (
                  <Image source={{ uri: parceiroImage }} style={styles.image} resizeMode="contain" />
                ) : (
                  <Loading />
                )}
              </View>
              <View style={[styles.image__border, { backgroundColor: parceiroData.borderColor }]}></View>
            </View>
            <View style={styles.titulo__container}>
              <LatoText style={[styles.titulo__parceiro, { color: parceiroData.typeColor }]}>{parceiroData.tipo}</LatoText>
              <LatoText style={[styles.titulo__nome, { color: parceiroData.textColor }]}>{parceiroData.nome}</LatoText>
            </View>
          </View>

          <View style={styles.descricao__container}>
            <ScrollView style={styles.descricao__scroll}>
              <LatoText style={[styles.descricao__texto, { color: parceiroData.textColor }]}>
                {parceiroData.descricao}
              </LatoText>
            </ScrollView>
            <TouchableOpacity
              style={[styles.descricao__botao, { borderColor: parceiroData.textColor }]}
              onPress={() => Linking.openURL(parceiroData.link)}
            >
              <LatoText style={[styles.descricao__botaoTexto, { color: parceiroData.textColor }]}>Saiba mais</LatoText>
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  containerBack: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',

  },
  imageBg: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButton: {
    position: 'absolute',
    top: 40,
    left: 20,
    zIndex: 1000,
  },
  container: {
    position: 'absolute',
    top: 80,
    justifyContent: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    marginBottom: 40
  },
  image__containerWrapper: {
    position: 'relative',
  },
  image__container: {
    width: 154,
    height: 154,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  image: {
    width: 98,
    height: 89
  },
  image__border: {
    position: 'absolute',
    width: 144,
    height: 144,
    borderRadius: 5,
    left: -15,
    bottom: -15,
    zIndex: 0,
  },
  titulo__container: {
    flexDirection: 'column'
  },
  titulo__parceiro: {
    fontSize: 14,
    fontWeight: 600,
  },
  titulo__nome: {
    fontSize: 20,
    fontWeight: 600,
  },
  descricao__scroll: {
    maxHeight: 300,
  },
  descricao__container: {
    maxWidth: 320,
    gap: 20,
  },
  descricao__texto: {
    fontSize: 14,
    fontWeight: 600,
  },
  descricao__botao: {
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    padding: 7,
    width: 130,
    justifyContent: 'center'
  },
  descricao__botaoTexto: {
    fontSize: 12,
    fontWeight: 600,
  },
});

export default Company;
