import api from "../util/axios";

export const getParticipantId = async (confSlug: string) => {
  return api.get(`/webapp/${confSlug}/participant`);
}

export const getParticipantById = async (participantId: string) => {
  return api.get(`/webapp/participant/${participantId}`);
}

export const scanQRCode = async (participantId: string, qrCodeId: string) => {
  try {
    const response = await api.post(`/webapp/explorer/${qrCodeId}`,{ participantId: participantId});
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error scanning QR code");
  }
}