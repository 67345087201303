//@ts-nocheck
import React from "react";
import { View, Text } from 'react-native';
import LatoText from "./LatoText";



const EducationInfoItem = ({label, value}) => {
  return <View flex={{ flex: 1}}>
      <LatoText style={{ margin:4, marginBottom: -2, color:'dimgray'}}>
        {label}
      </LatoText>
      <LatoText style={{margin:4, marginBottom:6, fontSize:20}}>
        {value}
      </LatoText>
    </View>;
};


export default EducationInfoItem;